import type { ReactNode } from 'react';

type FormGrid = {
  children: ReactNode;
};

export function FormGrid({ children }: FormGrid) {
  return (
    <div className='grid grid-cols-1 gap-6 lg:grid-cols-[repeat(11,minmax(0,1fr))] lg:[&>*]:col-span-4 lg:[&>:nth-child(3)]:col-span-3'>
      {children}
    </div>
  );
}
