import { type FC, memo, type ReactNode } from 'react';

import { SidebarPaneContainer } from '@/components/sidebarPane/SidebarPaneContainer';
import { SidebarPaneDrawer } from '@/components/sidebarPane/SidebarPaneDrawer';

import { SidebarPaneContextProvider } from './SidebarPaneContext';

interface SidebarPaneProps {
  children: ReactNode;
  isOpen: boolean;
}

interface SidebarPaneComponent extends FC<SidebarPaneProps> {
  Container: typeof SidebarPaneContainer;
  Drawer: typeof SidebarPaneDrawer;
}

function SidebarPaneBase({ children, isOpen }: SidebarPaneProps) {
  return (
    <SidebarPaneContextProvider isOpen={isOpen}>
      {children}
    </SidebarPaneContextProvider>
  );
}

export const SidebarPane = memo(
  SidebarPaneBase,
) as unknown as SidebarPaneComponent;

// Attach the Container and Drawer components
SidebarPane.Container = SidebarPaneContainer;
SidebarPane.Drawer = SidebarPaneDrawer;
