import type { SourceDocumentType } from '@kanbu/schema';
import { DocumentExternalSource } from '@kanbu/schema/enums';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Button, Dialog, Tooltip } from '@utima/ui';
import { FlaskConical } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';

import { GenerateTestCasesDialog } from './GenerateTestCasesDialog';
import { useSourceDocumentMutation } from './useSourceDocumentMutation';

const columnHelper = createColumnHelper<SourceDocumentType>();

export function useSourceDocumentsCols() {
  const { t } = useTranslation(['glossary']);
  const {
    remove: { mutateAsync: remove },
  } = useSourceDocumentMutation();

  return useMemo(
    () => [
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: t('glossary:labels.name'),
        meta: {
          sortable: 'name',
        },
        cell: ({ row }) => (
          <strong className='break-all'>{row.original.name}</strong>
        ),
      }),
      columnHelper.accessor(row => row.source, {
        id: 'source',
        header: t('glossary:labels.source'),
        meta: {
          sortable: 'source',
        },
        cell: ({ row }) => (
          <Badge
            size='xs'
            variant={
              row.original.source === DocumentExternalSource.Document
                ? 'info'
                : 'secondary'
            }
          >
            {row.original.source}
          </Badge>
        ),
      }),
      columnHelper.accessor(row => row.partsCount, {
        id: 'partsCount',
        header: t('glossary:labels.documentsCount'),
        meta: {
          sortable: 'partsCount',
        },
      }),
      columnHelper.accessor(row => row.metadata, {
        id: 'metadata',
        header: t('glossary:labels.metadata'),
        meta: {
          sortable: 'metadata',
        },
        cell: ({ row }) => (
          <Card className='bg-secondary/25 font-mono text-xs'>
            {Object.entries(row.original.metadata).map(([key, value]) => (
              <div key={key}>
                <span className='font-bold'>{key}:</span> {value as string}
              </div>
            ))}
          </Card>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: t('glossary:labels.actions'),
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })}>
            <Dialog.Root>
              <Tooltip title={t('glossary:actions.generateTestCases')}>
                <Dialog.Trigger asChild>
                  <Button variant='ghost' outline size='icon-xs'>
                    <FlaskConical className='size-4' />
                  </Button>
                </Dialog.Trigger>
              </Tooltip>
              <GenerateTestCasesDialog document={row.original} />
            </Dialog.Root>
          </ActionsCell>
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [remove, t],
  );
}
