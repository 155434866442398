import { z } from 'zod';

import { baseEntitySchema } from './baseContract';
import { DocumentExternalSource } from '../enums';

export const sourceDocumentPartSchema = baseEntitySchema.extend({
  content: z.string(),
  metadata: z.any(),
  parent: z.string().uuid(),
});

export const createSourceDocumentPartSchema = sourceDocumentPartSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

export const updateSourceDocumentPartSchema = sourceDocumentPartSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type SourceDocumentPartDTO = z.infer<typeof sourceDocumentPartSchema>;
export type CreateSourceDocumentPartDTO = z.infer<
  typeof createSourceDocumentPartSchema
>;
export type UpdateSourceDocumentPartDTO = z.infer<
  typeof updateSourceDocumentPartSchema
>;

/**
 * Schema for metadata which can be contained in the source document part.
 */
export const embeddedDocumentMetadataSchema = z.object({
  sourceId: z.string().uuid(),
  sourceUrl: z.string(),
  source: z.nativeEnum(DocumentExternalSource),
  page: z.number().optional(),
  loc: z
    .object({
      lines: z.object({
        from: z.number(),
        to: z.number(),
      }),
    })
    .optional(),
  fileName: z.string().optional(),
  title: z.string().optional(),
  description: z.string().optional(),
  language: z.string().optional(),
});

export type EmbeddedDocumentMetadataSchema = z.infer<
  typeof embeddedDocumentMetadataSchema
>;
