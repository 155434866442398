import type { ReactNode } from '@tanstack/react-router';
import { memo } from 'react';

export type ActionsCellWrapperProps = {
  children?: ReactNode;
};

export const ActionsCellWrapper = memo(function ActionsCellWrapper({
  children,
}: ActionsCellWrapperProps) {
  return (
    <div className='inline-flex items-center gap-2' data-table='prevent'>
      {children}
    </div>
  );
});
