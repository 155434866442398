import type { UserType } from '@kanbu/schema';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { createColumnHelper } from '@tanstack/react-table';
import { Tooltip, Button, toast } from '@utima/ui';
import { Send } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';

import { RoleBadge } from './components/RoleBadge';
import { VerifiedBadge } from './components/VerifiedBadge';
import { useUserMutation } from './useUserMutation';
import { trpc } from '../../services/trpc';

const columnHelper = createColumnHelper<UserType>();

export function useUsersCols() {
  const { t } = useTranslation(['glossary', 'users']);
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {},
  );

  const {
    remove: { mutateAsync: remove },
  } = useUserMutation();

  const { mutateAsync: sendVerify } = trpc.users.resendEmail.useMutation({
    onSettled: (_, __, variables) => {
      setLoadingStates(prev => ({ ...prev, [variables.id]: false }));
    },
    onSuccess: () => {
      toast.success(t('users:toasts.resendEmail.success.title'), {
        description: t('users:toasts.resendEmail.success.description'),
      });
    },
    onError: () => {
      toast.error(t('users:toasts.resendEmail.error.title'), {
        description: t('users:toasts.resendEmail.error.description'),
      });
    },
  });

  const handleSendVerify = useCallback(
    (id: string) => {
      setLoadingStates(prev => ({ ...prev, [id]: true }));
      sendVerify({ id });
    },
    [sendVerify],
  );

  return useMemo(
    () => [
      columnHelper.accessor(row => row.id, {
        id: 'id',
        header: t('glossary:labels.id'),
        meta: {
          sortable: 'id',
        },
      }),
      columnHelper.accessor(row => formatUtils.fullName(row), {
        id: 'name',
        header: t('glossary:labels.personName'),
        meta: {
          sortable: 'name',
        },
      }),
      columnHelper.accessor(row => row.email, {
        id: 'email',
        header: t('glossary:labels.email'),
        meta: {
          sortable: 'email',
        },
      }),
      columnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: t('glossary:labels.createdAt'),
        cell: row => formatUtils.date(row.getValue(), DateFormat.DateTime),
        meta: {
          sortable: 'createdAt',
        },
      }),
      columnHelper.accessor(row => row.role, {
        id: 'role',
        header: t('glossary:labels.role'),
        cell: ({ getValue }) => <RoleBadge role={getValue()} />,
        meta: {
          sortable: 'role',
        },
      }),
      columnHelper.accessor(row => row.verified, {
        id: 'verified',
        header: t('glossary:labels.status'),
        cell: ({ getValue }) => <VerifiedBadge verified={getValue()} />,
        meta: {
          sortable: 'verified',
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: t('glossary:labels.actions'),
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })}>
            {!row.original.verified && (
              <Tooltip title={t('users:texts.resendEmail')}>
                <Button
                  onClick={() => handleSendVerify(row.original.id)}
                  loading={loadingStates[row.original.id]}
                  disabled={loadingStates[row.original.id]}
                  variant='ghost'
                  size='icon-xs'
                  outline
                  icon={<Send className='size-4' />}
                />
              </Tooltip>
            )}
          </ActionsCell>
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [handleSendVerify, remove, loadingStates, t],
  );
}
