import type { Primitive } from 'type-fest';

const DIACRITICAL_MARKS_REGEX = /[\u0300-\u036F]/g;

/**
 * Remove diacritical marks from a string.
 */
export function unaccent(value: Primitive): string {
  if (!value) {
    return '';
  }

  return value
    .toString()
    .normalize('NFD')
    .replaceAll(DIACRITICAL_MARKS_REGEX, '');
}

/**
 * Remove diacritical marks from a string and convert it to lowercase.
 */
export function unaccentLower(value: Primitive): string {
  return unaccent(value).toLowerCase();
}

/**
 * Create a searchable index value from an array of primitive values.
 */
export function createSearchableIndexValue(values: Primitive[]): string {
  return values
    .map(value => unaccentLower(value))
    .join(' ')
    .trim();
}

/**
 * Create a search expression for a given value, suitable for
 * $like search queries.
 */
export function createSearchExpression(
  value: string | undefined | null,
): string | undefined {
  if (!value) {
    return undefined;
  }

  return `%${unaccentLower(value)}%`;
}
