import type { ChatUserType } from '@kanbu/schema';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useChatUsersCols } from '@/features/chatUsers/useChatUsersCols';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chat-users/')({
  component: ChatUsersPage,
});

export function ChatUsersPage() {
  const { t } = useTranslation(['glossary', 'globals', 'chatUsers']);
  const columns = useChatUsersCols();
  const handleQuery = useTableQuery(trpcClient.chatUsers.findAll.query, {
    populate: ['threadsCount'],
  });

  const handleRow = useDetailRowHandler<ChatUserType>(row => ({
    to: '/chat-users/$chatUserId/threads',
    params: {
      chatUserId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t('chatUsers:texts.manage')}
      breadcrumbs={[
        { label: t('globals:routeNames.chatUsers'), to: '/chat-users' },
      ]}
      columns={columns}
      queryKey={getQueryKey(trpc.chatUsers.findAll)}
      onQueryFn={handleQuery}
      onRow={handleRow}
    />
  );
}
