import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Error } from './Error';

export const NotFoundPage = memo(function NotFoundPage() {
  const { t } = useTranslation(['errors']);

  return (
    <Error
      statusCode={404}
      title={t('errors:httpStatus.404.title')}
      description={t('errors:httpStatus.404.message')}
    />
  );
});
