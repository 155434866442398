import { formatUtils } from '@kanbu/shared';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { ProfileForm } from '@/features/users/components/ProfileForm';
import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export const Route = createFileRoute('/_baseLayout/users/profile/')({
  component: ProfilePage,
  loader: async ({ context: { trpcUtils } }) =>
    trpcUtils.users.findOne.ensureData({
      id: useBoundStore.getState().user!.id,
    }),
});

export function ProfilePage() {
  const { t } = useTranslation(['glossary', 'users']);
  const user = useBoundStore(state => state.user);
  const [data] = trpc.users.findOne.useSuspenseQuery({ id: user!.id });

  return (
    <FormPage
      title={`${t('users:texts.profile')} - ${formatUtils.fullName(data)}`}
      breadcrumbs={[
        {
          label: t('glossary:labels.profile'),
          to: '/users/profile',
        },
      ]}
    >
      <ProfileForm data={data} />
    </FormPage>
  );
}
