import type { ChatType } from '@kanbu/schema';
import { createSearchExpression } from '@kanbu/schema/shared';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import type { DataProviderFactory } from '@/components/combobox/Combobox';
import { ComboboxRichItem } from '@/components/combobox/ComboboxRichItem';
import { trpc, trpcClient } from '@/services/trpc';

import {
  ComboboxControl,
  type ComboboxControlProps,
} from '../combobox/ComboboxControl';

export interface ChatSelectProps
  extends Omit<ComboboxControlProps<ChatType>, 'dataProviderFactory'> {}

const dataProvider: DataProviderFactory<ChatType, string> = () => ({
  query: async ({ pageSize, search }) => {
    const where: Record<string, unknown> = {};

    if (search) {
      where.searchable = { $like: createSearchExpression(search) };
    }

    try {
      const data = await trpcClient.chats.findAll.query({
        pagination: {
          pageSize,
        },
        orderBy: { name: 'asc' },
        where: {
          ...where,
        },
      });

      return {
        items: data.items,
        total: data.meta.total,
      };
    } catch (error) {
      console.error(error);
    }

    return {
      items: [],
      total: 0,
    };
  },

  cacheKeyFactory: params => [
    ...getQueryKey(trpc.chats.findAll),
    { orderBy: { name: 'asc' } },
    params,
  ],
});

export function ChatSelect({ onValueChange, ...restProps }: ChatSelectProps) {
  const { t } = useTranslation(['globals']);

  return (
    <ComboboxControl
      placeholder={t('globals:chatSelect.placeholder')}
      dataProviderFactory={dataProvider}
      renderSearchValue={item => `${item.name}`}
      renderDisplayValue={item => (
        <ComboboxRichItem
          type='avatar'
          readOnly={restProps.readOnly}
          title={item?.name}
          description={item?.id}
        />
      )}
      onValueChange={(value, item, prevValue) => {
        onValueChange?.(value, item, prevValue);
      }}
      shouldFilter={false}
      {...restProps}
    />
  );
}
