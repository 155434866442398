import { AlertDialog, Button, toast } from '@utima/ui';
import { useCallback, useState, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmDialogProps = {
  onConfirm?: () => Promise<unknown>;
  onCancel?: () => Promise<unknown>;
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

export function ConfirmDialog({
  open,
  onOpenChange,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) {
  const { t } = useTranslation(['globals', 'glossary']);
  const [confirming, setConfirming] = useState(false);
  const [canceling, setCanceling] = useState(false);

  const handleConfirm = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      try {
        setConfirming(true);
        await onConfirm?.();

        // Show success notification
        toast.success(t('globals:confirmDialog.successDelete.title'), {
          description: t('globals:confirmDialog.successDelete.message'),
        });
        onOpenChange(false);
      } finally {
        setConfirming(false);
      }
    },
    [onConfirm, t, onOpenChange],
  );

  const handleCancel = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      try {
        setCanceling(true);
        await onCancel?.();
        onOpenChange(false);
      } finally {
        setCanceling(false);
      }
    },
    [onCancel, onOpenChange],
  );

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content>
          <AlertDialog.Header>
            <AlertDialog.Title>
              {t('globals:confirmDialog.title')}
            </AlertDialog.Title>
            <AlertDialog.Description>
              {t('globals:confirmDialog.message')}
            </AlertDialog.Description>
          </AlertDialog.Header>
          <AlertDialog.Footer>
            <AlertDialog.Cancel asChild>
              <Button
                onClick={handleCancel}
                loading={canceling}
                disabled={canceling || confirming}
                variant='ghost'
              >
                {t('glossary:actions.cancel')}
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button
                variant='danger'
                onClick={handleConfirm}
                loading={confirming}
                disabled={canceling || confirming}
              >
                {t('glossary:actions.confirm')}
              </Button>
            </AlertDialog.Action>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
