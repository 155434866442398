import type { ChatMessageType } from '@kanbu/schema';
import { ToolNames, type RagResultToolDef } from '@kanbu/schema/contracts';
import { ChatRole, ToolType } from '@kanbu/schema/enums';
import { DateFormat, formatUtils, renderMarkdown } from '@kanbu/shared';
import { cn } from '@utima/ui';
import { memo } from 'react';

import Feedback from '@/components/chat/Feedback.tsx';

export type MessageProps = {
  message: ChatMessageType;
};

export const Message = memo(function Message({ message }: MessageProps) {
  const { role, toolCalls, createdAt } = message;
  const content =
    message?.content ??
    toolCalls
      ?.map(tool => {
        if (tool.type === ToolType.Text) {
          return tool.content;
        }

        if (tool.name === ToolNames.RagResult) {
          return (
            tool.args.content +
            '\n\n' +
            tool.args.sources
              .map(
                (source: Required<RagResultToolDef>['sources'][number]) =>
                  `- [${source.fileName}](${source.sourceUrl})`,
              )
              .join('\n')
          );
        }

        return '';
      })
      .join('\n\n');

  return (
    <div className={cn('flex flex-col gap-1')}>
      <div
        className={cn(
          'flex max-w-[90%] rounded-2xl px-3.5 py-3 text-darkGray',
          {
            ['rounded-br-none ml-auto bg-secondary']: role === ChatRole.User,
            ['rounded-bl-none bg-gray-200']: role === ChatRole.Assistant,
          },
        )}
      >
        <div
          className='markdown-wrapper -mb-4 text-sm'
          dangerouslySetInnerHTML={{ __html: renderMarkdown(content) }}
        />
      </div>
      {message.feedback && <Feedback feedback={message.feedback} />}
      <div
        className={cn('text-xs mt-1', {
          ['text-right']: role === ChatRole.User,
          ['text-left']: role === ChatRole.Assistant,
        })}
      >
        {formatUtils.date(createdAt, DateFormat.DateTime)}
      </div>
    </div>
  );
});
