import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { OrganizationForm } from '@/features/organizations/OrganizationForm';

export const Route = createFileRoute('/_baseLayout/organizations/create')({
  component: OrganizationsCreatePage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
});

export function OrganizationsCreatePage() {
  const { t } = useTranslation(['globals', 'organizations']);

  return (
    <FormPage
      title={t('organizations:texts.create')}
      breadcrumbs={[
        { label: t('globals:routeNames.organizations'), to: '/organizations' },
        { label: t('organizations:texts.create'), to: '/organizations/create' },
      ]}
    >
      <OrganizationForm />
    </FormPage>
  );
}
