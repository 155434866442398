import { Command, useCommandState } from '@utima/ui';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type ComboboxGroupProps = {
  total: number;
  children: ReactNode;
};

/**
 * We need to create a custom group component to be able to
 * subscribe to command store using useCommandState hook.
 *
 * This component will be used to group the combobox options
 * and display the current count of the filtered items.
 */
export function ComboboxGroup({ children, total }: ComboboxGroupProps) {
  const { t } = useTranslation('globals');
  const count = useCommandState(state => state.filtered.count);

  // Calculate the heading based on the current count
  const groupHeading = t('combobox.groupHeading', {
    total: total,
    current: count > total ? total : count,
  });

  return <Command.Group heading={groupHeading}>{children} </Command.Group>;
}
