import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

export type ChatConfigFieldsProps = {};

export const ChatConfigFields = memo(
  function ChatConfigFields({}: ChatConfigFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums', 'chats']);
    const { schema } = useFieldsSchema(
      [
        {
          name: 'chatbotConfig.systemName',
          label: t('glossary:labels.systemName'),
          defaultValue: 'Kanbu',
          required: true,
        },
        {
          name: 'chatbotConfig.systemAvatar',
          label: t('glossary:labels.systemAvatar'),
        },
        {
          name: 'chatbotConfig.initialMessage',
          label: t('glossary:labels.initialMessage'),
          type: 'text',
          uiControl: 'textarea',
          defaultValue: t('chats:texts.initialMessage'),
        },
        {
          name: 'chatbotConfig.userName',
          label: t('glossary:labels.userName'),
        },
        {
          name: 'chatbotConfig.bubbleTitle',
          label: t('glossary:labels.bubbleTitle'),
        },
        {
          name: 'chatbotConfig.bubbleDescription',
          label: t('glossary:labels.bubbleDescription'),
        },
        {
          name: 'chatbotConfig.maxMessages',
          label: t('glossary:labels.maxMessages'),
          type: 'number',
        },
        {
          name: 'chatbotConfig.maxCharacters',
          label: t('glossary:labels.maxCharacters'),
          type: 'number',
        },
      ],
      [t],
    );

    return (
      <Card collapsed title={t('glossary:labels.chatbotConfig')}>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
