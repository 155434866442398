import { Navigate, createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@utima/ui';
import { Check, Mail } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/_authLayout/forgotten-password/sent/')({
  component: ForgottenPasswordSentPage,
  validateSearch: (search: Record<string, unknown>): { email: string } => {
    return {
      email: (search.email as string) || '',
    };
  },
});

export function ForgottenPasswordSentPage() {
  const { t } = useTranslation(['glossary', 'auth']);
  const { email } = Route.useSearch();

  if (!email) {
    return <Navigate to='/login' />;
  }

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-center text-2xl font-bold'>
        {t('auth:texts.forgottenPasswordSent.title')}
      </h1>

      <div className='relative rounded-radius bg-primary/15 px-4 py-3'>
        <Mail className='size-[42px] text-primary' />
        <div className='absolute -right-2 -top-2'>
          <div className='flex size-6 items-center justify-center rounded-full bg-success'>
            <Check className='size-4 text-white' strokeWidth={3} />
          </div>
        </div>
      </div>

      <div className='text-center text-sm'>
        {t('auth:texts.forgottenPasswordSent.subtitle')}
      </div>

      <Button variant='primary' asChild>
        <Link to='/login' className='w-full'>
          {t('glossary:actions.backToLogin')}
        </Link>
      </Button>
    </div>
  );
}
