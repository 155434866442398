import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { OrganizationForm } from '@/features/organizations/OrganizationForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute(
  '/_baseLayout/organizations/$organizationId/edit',
)({
  component: OrganizationsEditPage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
  loader: async ({ context: { trpcUtils }, params: { organizationId } }) =>
    trpcUtils.organizations.findOne.ensureData({ id: organizationId }),
});

export function OrganizationsEditPage() {
  const { organizationId } = Route.useParams();
  const { t } = useTranslation(['glossary', 'organizations']);
  const [data] = trpc.organizations.findOne.useSuspenseQuery({
    id: organizationId,
  });

  return (
    <FormPage
      title={`${t('organizations:texts.edit')} - ${data.name}`}
      breadcrumbs={[
        { label: t('organizations:texts.manage'), to: '/organizations' },
        {
          label: data.name,
          to: '/organizations/$organizationId/edit',
          params: { organizationId },
        },
      ]}
    >
      <OrganizationForm data={data} />
    </FormPage>
  );
}
