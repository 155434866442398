import { createSearchExpression } from '@kanbu/schema/shared';
import type {
  FieldsParams,
  FindAllParams,
  PopulateParams,
  WhereParams,
} from '@kanbu/shared';
import { useCallback } from 'react';
import type { RequiredDeep } from 'type-fest';

import type { DataTableQuery } from '@/components/dataTable/DataTable';

type RequiredFindAllParams = RequiredDeep<FindAllParams>;
type QueryFn<TData> = (params: RequiredFindAllParams) => Promise<{
  items: TData[];
  meta: {
    pageCount: number;
  };
}>;

type UseTableQueryOptions<TData> = {
  // Fields to include in the query.
  fields?: FieldsParams;
  // Populate fields.
  populate?: PopulateParams;
  // Custom where clause.
  where?: WhereParams;
  /**
   * Set to true, when the entity has a searchable field,
   * defaults to true.
   */
  searchable?: boolean;
  /**
   * Callback function to execute after the query is successful.
   */
  onSuccess?: (data: TData[]) => void;
};

/**
 * Simplified wrapper around DataTableQuery hook.
 */
export function useTableQuery<TData>(
  queryFn: QueryFn<TData>,
  options?: UseTableQueryOptions<TData>,
): DataTableQuery<TData> {
  return useCallback(
    async ({ page, pageSize, sort, search }) => {
      const { searchable = true, onSuccess, ...restOptions } = options ?? {};
      const where = searchable
        ? { searchable: { $like: createSearchExpression(search) } }
        : {};

      const data = await queryFn({
        ...restOptions,
        pagination: {
          page,
          pageSize,
        },
        orderBy: sort,
        where: {
          ...where,
          ...restOptions?.where,
        },
      } as never);

      // Execute callback function after the query is successful.
      onSuccess?.(data.items);

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [queryFn, options],
  );
}
