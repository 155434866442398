import { Label } from '@utima/ui';
import { useFieldState } from 'informed';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';

import {
  MIN_LENGTH,
  SPECIAL_CHARS,
  passwordValidate,
  PasswordValidationError,
} from './passwordValidate';
import { ValidationIcon } from './ValidationIcon';

type PasswordHintProps = {
  className?: string;
};

export const PasswordHint = memo(function ({ className }: PasswordHintProps) {
  const { t } = useTranslation(['globals']);
  const { value: password } = useFieldState('password');
  const { value: confirmPassword } = useFieldState('confirmPassword');

  const validationErrors = passwordValidate(
    password as string,
    confirmPassword as string,
  );

  return (
    <Card className={className} title={t('globals:passwordFields.title')}>
      <Label className='inline-flex items-center gap-1.5'>
        <ValidationIcon
          validationErrors={validationErrors}
          targetValidationError={PasswordValidationError.LENGTH}
        />
        {t('globals:passwordFields.hintLength', {
          length: MIN_LENGTH,
        })}
      </Label>
      <Label className='inline-flex items-center gap-1.5'>
        <ValidationIcon
          validationErrors={validationErrors}
          targetValidationError={PasswordValidationError.UPPERCASE}
        />
        {t('globals:passwordFields.hintUppercase')}
      </Label>
      <Label className='inline-flex items-center gap-1.5'>
        <ValidationIcon
          validationErrors={validationErrors}
          targetValidationError={PasswordValidationError.NUMBER}
        />
        {t('globals:passwordFields.hintNumbers')}
      </Label>
      <Label className='inline-flex items-center gap-1.5'>
        <ValidationIcon
          validationErrors={validationErrors}
          targetValidationError={PasswordValidationError.SPECIAL}
        />
        {t('globals:passwordFields.hintSpecial', {
          specialCharacters: SPECIAL_CHARS.split('').slice(0, 4).join(','),
        })}
      </Label>
    </Card>
  );
});
