import type { SourceDocumentType } from '@kanbu/schema';
import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useSourceDocumentsCols } from '@/features/sourceDocuments/useSourceDocumentsCols';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chats/$chatId/documents/')({
  component: SourceDocumentsPage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin, Role.TmpAdmin]),
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId }),
});

export function SourceDocumentsPage() {
  const { chatId } = Route.useParams();
  const { t } = useTranslation(['globals', 'sourceDocuments', 'chats']);
  const columns = useSourceDocumentsCols();
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });

  const handleQuery = useTableQuery(trpcClient.sourceDocuments.findAll.query, {
    searchable: false,
    populate: ['partsCount'],
    where: {
      chat: chatId,
    },
  });

  const handleRow = useDetailRowHandler<SourceDocumentType>(row => ({
    to: '/chats/$chatId/documents/$sourceDocumentId/parts',
    params: {
      chatId,
      sourceDocumentId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t('sourceDocuments:texts.manage')}
      breadcrumbs={[
        {
          label: t('globals:routeNames.chats'),
          to: '/chats',
        },
        {
          label: data.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t('globals:routeNames.sourceDocuments'),
          to: '/chats/$chatId/documents',
          params: { chatId },
        },
      ]}
      columns={columns}
      queryKey={[...getQueryKey(trpc.sourceDocuments.findAll), { chatId }]}
      onQueryFn={handleQuery}
      onRow={handleRow}
      showSearch={false}
    />
  );
}
