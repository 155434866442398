import { z } from 'zod';

import { baseEntitySchema } from './baseContract';
import { DocumentExternalSource } from '../enums';

export const sourceDocumentSchema = baseEntitySchema.extend({
  source: z.nativeEnum(DocumentExternalSource),
  chat: z.string().uuid(),
});

export const createSourceDocumentSchema = sourceDocumentSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

export const updateSourceDocumentSchema = sourceDocumentSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type SourceDocumentDTO = z.infer<typeof sourceDocumentSchema>;
export type CreateSourceDocumentDTO = z.infer<
  typeof createSourceDocumentSchema
>;
export type UpdateSourceDocumentDTO = z.infer<
  typeof updateSourceDocumentSchema
>;
