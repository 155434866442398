export enum Rating {
  Neutral = 'neutral',
  Positive = 'positive',
  Negative = 'negative',
}

export enum ChatRole {
  User = 'user',
  Assistant = 'assistant',
}

export enum Role {
  SuperAdmin = 'superAdmin',
  TmpAdmin = 'tmpAdmin', // This is used only for web summit, will be removed in the future
  Admin = 'admin',
}

export enum RoleWOSuperAdmin {
  Admin = 'admin',
}

export enum Agent {
  Rag = 'rag',
  RagV2 = 'ragV2', // Uses weaviate as a vector database among other things
  ConversationalRag = 'conversationalRag',
}

/**
 * OpenAI: https://platform.openai.com/docs/models/gpt-4o
 * Anthropic: https://docs.anthropic.com/en/docs/about-claude/models#model-comparison-table
 */
export enum ChatModel {
  Claude3Opus = 'claude-3-opus-20240229',
  Claude35Sonnet20241022 = 'claude-3-5-sonnet-20241022',
  Claude35Sonnet20240620 = 'claude-3-5-sonnet-20240620',
  Claude35Sonnet = 'claude-3-5-sonnet-latest',
  Claude3Haiku = 'claude-3-haiku-20240307',
  Gpt4o_2024_08_06 = 'gpt-4o-2024-08-06',
  Gpt4o = 'gpt-4o',
  Gpt4oMini = 'gpt-4o-mini',
  GptO1 = 'gpt-o1',
  GptO1_Mini = 'gpt-o1-mini',
}

export enum DocumentExternalSource {
  Website = 'website',
  Document = 'document',
}

export enum UsageRecordOperation {
  Chat = 'chat',
  Embedding = 'embedding',
  Rerank = 'rerank',
}

export enum ToolType {
  Text = 'text',
  Tool = 'tool',
}

export enum TonePreset {
  Friendly = 'friendly',
  Casual = 'casual',
  Balanced = 'balanced',
  Professional = 'professional',
  Formal = 'formal',
}

export enum ResponseLengthPreset {
  Concise = 'concise', // Ultra short, just facts
  Balanced = 'balanced', // Normal conversational length
  Detailed = 'detailed', // Comprehensive with examples
}

export enum Language {
  Czech = 'cs',
  English = 'en',
}
