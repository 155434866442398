/**
 * Error thrown when a user is not allowed to perform an action.
 * This error should be caught and handled by the application.
 */
export class ForbiddenError extends Error {
  status = 403;

  constructor(message?: string) {
    super(message);

    this.name = 'ForbiddenError';
  }
}
