import { cn, Command } from '@utima/ui';
import { Check } from 'lucide-react';
import { memo, type ComponentPropsWithoutRef, useCallback } from 'react';

import type { RenderChildrenProps } from './Combobox';
import { useComboboxContext } from './useComboboxContext';

type ComboboxItemProps<TValue, TData> = Omit<
  ComponentPropsWithoutRef<typeof Command.Item>,
  'onSelect' | 'value'
> & {
  /**
   * Value that is use in for filtration in combobox search.
   */
  searchValue: string;
  /**
   * Like in Select, value that is the result of selection (usually ID, or some key).
   */
  value: TValue;
  /**
   * Item data, usually whole data object, used outside for displaying rich values.
   */
  item: TData;
  /**
   * Callback for item selection.
   */
  onSelect: RenderChildrenProps<TValue, TData>['onSelect'];
  /**
   * Optionally hide checkmark, usefull for certain cases.
   */
  showCheckmark?: boolean;
};

/**
 * Wrapper around Command.Item with checkmark for Combobox.
 * And some performance optimizations.
 */
export function ComboboxItemComponent<TValue, TData>({
  className,
  children,
  value,
  searchValue,
  item,
  showCheckmark = true,
  onSelect,
  ...restProps
}: ComboboxItemProps<TValue, TData>) {
  const { currentValue } = useComboboxContext();
  const checked = value === currentValue;

  /**
   * Update selected value and selected item. When the same
   * item is selected, it will be deselected.
   */
  const handleSelect = useCallback(() => {
    onSelect(
      value === currentValue ? undefined : value,
      value === currentValue ? undefined : item,
      currentValue as TValue,
    );
  }, [value, item, onSelect, currentValue]);

  return (
    <Command.Item
      value={searchValue}
      className={cn(
        'justify-between w-full overflow-hidden',
        checked && 'bg-accent/50 font-semibold',
      )}
      onSelect={handleSelect}
      {...restProps}
    >
      <div className={className}>{children}</div>
      {showCheckmark && (
        <Check
          className={cn('size-4', checked ? 'opacity-100' : 'opacity-0')}
        />
      )}
    </Command.Item>
  );
}

/**
 * Memoized Command.Item with checkmark for Combobox.
 * Needs to be exported this way to support generic inference.
 */
export const ComboboxItem = memo(
  ComboboxItemComponent,
) as typeof ComboboxItemComponent;
