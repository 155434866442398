import { Select, SelectItem } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import { SUPPORTED_LANGUAGES } from '@/services/i18n';

export function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { t } = useTranslation(['enums']);

  return (
    <div className='w-36'>
      <Select
        value={i18n.language}
        onValueChange={value => {
          i18n.changeLanguage(value);
        }}
      >
        {SUPPORTED_LANGUAGES.map(lang => (
          <SelectItem key={lang} value={lang}>
            {t(`enums:language.${lang}` as never)}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
}
