import Editor, { type Monaco } from '@monaco-editor/react';
import { useCallback } from 'react';

/**
 * JSON schema for evaluation test cases
 */
const testCaseSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      messages: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            question: { type: 'string' },
            expectedOutput: { type: 'string' },
          },
          required: ['question', 'expectedOutput'],
        },
      },
    },
    required: ['messages'],
  },
};

const placeholder = `[
  {
    "messages": [
      {
        "expectedOutput": "Ostravská pobočka má rozlohu 1130 m²",
        "question": "Jakou rozlohu má Ostravská pobočka?"
      },
      {
        "question": "Jakou má adresu?",
        "expectedOutput": "AVION SHOPPING PARK, Rudná 3114, Ostrava – Zábřeh."
      }
    ]
  },
  {
    "messages": [
      {
        "question": "Kde máte pobočky?",
        "expectedOutput": "Máme pobočky v Praze (17 poboček), Brně (3 pobočky) a Ostravě (1 pobočka)."
      }
    ]
  }
]`;

interface EvaluationEditorProps {
  onChange: (value: string) => void;
}

export function EvaluationEditor({ onChange }: EvaluationEditorProps) {
  const handleEditorChange = useCallback(
    (value: string | undefined) => {
      if (value) {
        onChange(value);
      }
    },
    [onChange],
  );

  const handleEditorDidMount = useCallback((editor: any, monaco: Monaco) => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: 'http://myserver/test-case-schema.json',
          fileMatch: ['*'],
          schema: testCaseSchema,
        },
      ],
    });
  }, []);

  return (
    <Editor
      height='100%'
      defaultLanguage='json'
      defaultValue={placeholder}
      onChange={handleEditorChange}
      onMount={handleEditorDidMount}
      options={{
        minimap: { enabled: false },
        fontSize: 12,
        formatOnPaste: true,
        formatOnType: true,
      }}
    />
  );
}
