import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { toast } from '@utima/ui';
import { getI18n } from 'react-i18next';

type ErrorResponseData = {
  code: string;
  path: string;
  httpStatus: number;
  stack?: string;
};

const { t, exists } = getI18n();

/**
 * Default global app error handler.
 */
function defaultErrorHandler(error: unknown) {
  if (typeof error === 'object' && error && 'data' in error && error.data) {
    const { httpStatus } = error.data as ErrorResponseData;
    const status = httpStatus ?? ('status' in error ? error.status : undefined);

    if (status && exists(`errors:httpStatus.${status}.title`)) {
      return toast.error(t(`errors:httpStatus.${status}.title` as never), {
        description: t(`errors:httpStatus.${status}.message` as never),
      });
    }
  }

  return toast.error(t('errors:generic.title'), {
    description: t('errors:generic.message'),
  });
}

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      return defaultErrorHandler(error);
    },
  }),
  queryCache: new QueryCache({
    onError: (error, query) => {
      return defaultErrorHandler(error);
    },
  }),
  defaultOptions: {
    mutations: {},
    queries: {
      staleTime: 30000,
      retry: false,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});
