import type { OrganizationType } from '@kanbu/schema';
import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useOrganizationsCols } from '@/features/organizations/useOrganizationsCols';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/organizations/')({
  component: OrganizationsPage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
});

export function OrganizationsPage() {
  const { t } = useTranslation(['globals', 'organizations']);
  const columns = useOrganizationsCols();
  const handleQuery = useTableQuery(trpcClient.organizations.findAll.query);
  const handleRow = useDetailRowHandler<OrganizationType>(row => ({
    to: '/organizations/$organizationId/edit',
    params: {
      organizationId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t('organizations:texts.manage')}
      breadcrumbs={[
        { label: t('globals:routeNames.organizations'), to: '/organizations' },
      ]}
      createButton={{
        to: '/organizations/create',
        label: t('organizations:actions.create'),
      }}
      columns={columns}
      queryKey={getQueryKey(trpc.organizations.findAll)}
      onQueryFn={handleQuery}
      onRow={handleRow}
    />
  );
}
