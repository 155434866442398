import { z } from 'zod';

import { DocumentExternalSource } from '../enums';

export const ToolNames = {
  RagResult: 'rag_result',
} as const;

export const ragResultToolDef = z.object({
  content: z
    .string()
    .describe(
      'Main response with factual information from documents, formatted in markdown',
    ),
  sources: z
    .array(
      z.object({
        type: z
          .nativeEnum(DocumentExternalSource)
          .describe('Source document type'),
        sourceUrl: z.string().describe('Source document URL from metadata'),
        fileName: z
          .string()
          .describe(
            'Prettified source document file name from metadata. Remove underscores and capitalize. Drop file extensions.',
          ),
      }),
    )
    .optional()
    .describe('List of source documents referenced in the response'),
});

export type RagResultToolDef = z.infer<typeof ragResultToolDef>;
