import { useNavigate, type Register } from '@tanstack/react-router';
import type { Row } from '@tanstack/react-table';
import { useCallback } from 'react';
import type { LiteralUnion } from 'type-fest';

import type { DataTableRowHandler } from '@/components/dataTable/DataTable';

export function useDetailRowHandler<TData = unknown>(
  handler: (row: Row<TData>) => {
    to?: LiteralUnion<keyof Register['router']['routesByPath'], string>;
    params?: Record<string, string>;
  },
): DataTableRowHandler<TData> {
  const navigate = useNavigate();

  return useCallback(
    (event, row) => {
      const { to, params } = handler(row);

      navigate({
        to,
        params,
      });
    },
    [handler, navigate],
  );
}
