import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export function useUserMutation() {
  const user = useBoundStore(state => state.user);
  const utils = trpc.useUtils();

  const create = trpc.users.create.useMutation({
    onSuccess: async () => utils.users.findAll.invalidate(),
  });

  const update = trpc.users.update.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.users.findAll.invalidate(),
        utils.users.findOne.invalidate({ id }),
        user?.id === id ? utils.users.me.invalidate() : null,
      ]),
  });

  const remove = trpc.users.delete.useMutation({
    onSuccess: async (data, { id }) => utils.users.findAll.invalidate(),
  });

  return {
    create,
    update,
    remove,
  };
}
