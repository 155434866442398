import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { Layout } from '@utima/ui';
import { useEffect } from 'react';

import { Menu } from '@/components/menu/Menu';
import TopBar from '@/components/topBar/TopBar';
import { AppSettings } from '@/constants/AppSettings';
import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export const Route = createFileRoute('/_baseLayout')({
  beforeLoad: async () => {
    if (useBoundStore.getState().jwt === null) {
      throw redirect({
        to: '/login',
        search: {
          // Use the current location to power a redirect after login
          redirect: location.pathname,
        },
      });
    }
  },
  component: BaseLayout,
});

export function BaseLayout() {
  const setUser = useBoundStore(state => state.setUser);
  const userQuery = trpc.users.me.useQuery(undefined, {
    staleTime: 15_000 * 60, // 15 minutes,
  });

  useEffect(() => {
    if (!userQuery.data) {
      return;
    }

    setUser(userQuery.data);
  }, [setUser, userQuery.data]);

  return (
    <Layout.Root>
      <Layout.Header className='gap-3 border-b px-3 sidebar:px-7'>
        <TopBar />
      </Layout.Header>
      <Layout.Sidebar className='flex flex-col justify-between border-r p-3 text-foreground'>
        <Menu />
        <div className='mt-8 px-4 pt-4'>
          <p className='text-center text-sm text-foreground'>
            v{AppSettings.version}
          </p>
        </div>
      </Layout.Sidebar>
      <Layout.Content className='bg-gray-50 p-6'>
        <div className='container mx-auto'>
          <Outlet />
        </div>
      </Layout.Content>
    </Layout.Root>
  );
}
