import { Badge } from '@utima/ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export type VerifiedBadgeProps = {
  verified: boolean;
};

export const VerifiedBadge = memo(function VerifiedBadge({
  verified,
}: VerifiedBadgeProps) {
  const { t } = useTranslation(['glossary']);

  return verified ? (
    <Badge variant='success' size='sm'>
      {t('glossary:labels.verified')}
    </Badge>
  ) : (
    <Badge variant='danger' size='sm'>
      {t('glossary:labels.notVerified')}
    </Badge>
  );
});
