import type { Role } from '@kanbu/schema/enums';

import { useBoundStore } from '@/store/store';

export function useAccess() {
  const user = useBoundStore(state => state.user);

  return {
    hasAccess: (roles: Role[]) => user?.role && roles.includes(user?.role),
  };
}
