import { useDebounce } from '@uidotdev/usehooks';
import { Input } from '@utima/ui';
import { Search, X } from 'lucide-react';
import {
  memo,
  useCallback,
  useEffect,
  useState,
  type ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

type TableHeaderProps = {
  showSearch?: boolean;
  search?: string;
  onSearchChange?: (search: string | undefined) => void;
};

export const TableHeader = memo(function TableHeader({
  search: defaultSearch,
  showSearch,
  onSearchChange,
}: TableHeaderProps) {
  const { t } = useTranslation(['glossary']);
  const [search, setSearch] = useState<string | undefined>(defaultSearch);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    onSearchChange?.(debouncedSearch);
  }, [debouncedSearch, onSearchChange]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  // Reset search immediately when the user clicks the X button
  const handleReset = useCallback(() => {
    setSearch('');
    onSearchChange?.('');
  }, [onSearchChange]);

  return (
    <div className='mb-3 flex w-full flex-col justify-between gap-4 sm:flex-row'>
      <div className='grow sm:max-w-72'>
        {showSearch && (
          <div className='relative'>
            <span className='absolute left-2 top-2 z-10'>
              <Search size={18} />
            </span>
            <Input
              className='pl-8'
              type='text'
              name='search'
              value={search ?? ''}
              placeholder={t('actions.search')}
              onChange={onChange}
            />
            {search && (
              <button
                type='button'
                onClick={handleReset}
                className='absolute right-2 top-2 z-10 transition-all hover:text-primary'
              >
                <X size={18} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
