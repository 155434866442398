import { useRouter } from '@tanstack/react-router';
import { Button } from '@utima/ui';
import { Trash, Save, ArrowLeft } from 'lucide-react';
import { memo, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

export type ActionBarProps = {
  showDelete?: boolean;
  loading?: boolean;
  onDelete?: (event: SyntheticEvent<HTMLButtonElement>) => void;
};

export const ActionBar = memo(function ActionBar({
  showDelete = true,
  loading,
  onDelete,
}: ActionBarProps) {
  const { t } = useTranslation(['glossary']);
  const router = useRouter();

  return (
    <div
      className='sticky bottom-6 z-10 mt-4 flex w-full justify-between rounded-xl border bg-white p-2'
      data-testid='action-bar'
    >
      <Button
        variant='secondary'
        icon={<ArrowLeft size={18} />}
        onClick={() => router.history.go(-1)}
      >
        <span className='hidden sm:inline'>
          {t('glossary:actions.abortChanges')}
        </span>
      </Button>
      <div className='flex gap-x-3'>
        {showDelete && onDelete && (
          <Button
            onClick={onDelete}
            disabled={loading}
            type='button'
            variant='danger'
            icon={<Trash size={18} />}
          >
            <span className='hidden sm:inline'>
              {t('glossary:actions.delete')}
            </span>
          </Button>
        )}
        <Button
          loading={loading}
          disabled={loading}
          variant='success'
          type='submit'
          icon={<Save size={18} />}
        >
          <span className='hidden sm:inline'>{t('glossary:actions.save')}</span>
        </Button>
      </div>
    </div>
  );
});
