import type { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { Toast } from '@utima/ui';
import { Suspense } from 'react';

import { ErrorPage } from '@/components/error/ErrorPage';
import { NotFoundPage } from '@/components/error/NotFoundPage';
import { AppSettings } from '@/constants/AppSettings';
import type { accessGuard } from '@/lib/routerUtils';
import type { trpcUtils } from '@/services/trpc';

export interface RouterContext {
  queryClient: QueryClient;
  trpcUtils: typeof trpcUtils;
  accessGuard: typeof accessGuard;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootLayout,
  errorComponent: ErrorPage,
  notFoundComponent: NotFoundPage,
});

export function RootLayout() {
  return (
    <div className='text-foreground'>
      <Outlet />
      <Toast closeButton position='top-right' />
      {AppSettings.debug && (
        <>
          <Suspense fallback={null}>
            <div className='ml-16'>
              <TanStackRouterDevtools position='bottom-right' />
            </div>
          </Suspense>
          <Suspense fallback={null}>
            <ReactQueryDevtools buttonPosition='bottom-right' />
          </Suspense>
        </>
      )}
    </div>
  );
}
