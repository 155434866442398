import type { UserType } from '@kanbu/schema';
import { createUserSchema, updateUserSchema } from '@kanbu/schema/contracts';
import { Role, RoleWOSuperAdmin } from '@kanbu/schema/enums';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useBoundStore } from '@/store/store';

import { useUserMutation } from '../useUserMutation';

export type UserFormProps = {
  data?: UserType;
};

export const UserForm = memo(function UserForm({ data }: UserFormProps) {
  const { t } = useTranslation(['glossary', 'enums']);
  const user = useBoundStore(state => state.user);
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useUserMutation();

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'firstName',
        label: t('glossary:labels.firstName'),
        required: true,
      },
      {
        name: 'lastName',
        label: t('glossary:labels.lastName'),
        required: true,
      },
      {
        name: 'email',
        label: t('glossary:labels.email'),
        required: true,
        disabled: !!data,
      },
      {
        name: 'role',
        label: t('glossary:labels.role'),
        type: 'select',
        options: optionsFromEnum(
          user?.role === Role.SuperAdmin ? Role : RoleWOSuperAdmin,
          key => t(`enums:role.${key}`),
        ),
        required: true,
      },
    ],
    [t],
  );

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createUserSchema}
      updateSchema={updateUserSchema.extend({
        email: z.string().email().max(255),
      })}
      onCreate={create}
      onUpdate={values => {
        const { email, ...restValues } = values;

        return update(restValues);
      }}
      onRemove={remove}
      basePath='/users'
      idParamName='userId'
    >
      <EntityCRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col />
      <EntityCRUDForm.Col>
        <MetaFields data={data} />
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
