import type { ThreadType } from '@kanbu/schema';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatSidebar } from '@/components/chat/ChatSidebar.tsx';
import type { DataTableRowHandler } from '@/components/dataTable/DataTable';
import { TablePage } from '@/components/page/TablePage';
import { SidebarPane } from '@/components/sidebarPane/SidebarPane.tsx';
import { useThreadsCols } from '@/features/threads/useThreadsCols';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpcClient, trpc } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/threads/')({
  component: ThreadsPage,
});

export function ThreadsPage() {
  const { t } = useTranslation(['threads', 'globals']);
  const columns = useThreadsCols({ showUserColumn: true });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [thread, setThread] = useState<ThreadType | null>(null);

  const handleQuery = useTableQuery(trpcClient.threads.findAll.query, {
    populate: [
      'chatMessageCount',
      'messages.feedback',
      'chatUser',
      'feedbackCount',
    ],
  });

  const handleRow: DataTableRowHandler<ThreadType> = useCallback(
    (event, row) => {
      setIsDrawerOpen(true);
      setThread(row.original);
    },
    [],
  );

  return (
    <SidebarPane isOpen={isDrawerOpen}>
      <SidebarPane.Container>
        <div className='flex grow flex-col'>
          <TablePage
            title={t('threads:texts.manage')}
            breadcrumbs={[
              { label: t('globals:routeNames.threads'), to: '/threads' },
            ]}
            columns={columns}
            queryKey={getQueryKey(trpc.threads.findAll)}
            onQueryFn={handleQuery}
            onRow={handleRow}
          />
        </div>
      </SidebarPane.Container>
      <SidebarPane.Drawer>
        <ChatSidebar thread={thread} setDrawerOpen={setIsDrawerOpen} />
      </SidebarPane.Drawer>
    </SidebarPane>
  );
}
