import { Button } from '@utima/ui';
import { useFormApi } from 'informed';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

export type ChatThemeFieldsProps = {};

export const ChatThemeFields = memo(
  function ChatThemeFields({}: ChatThemeFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums']);
    const { setValue } = useFormApi();
    const { schema } = useFieldsSchema(
      [
        {
          name: 'themeConfig.primary',
          label: t('glossary:labels.primaryBg'),
          uiProps: {
            type: 'color',
          },
        },
        {
          name: 'themeConfig.primaryFg',
          label: t('glossary:labels.primaryFg'),
          uiProps: {
            type: 'color',
          },
        },
        {
          name: 'themeConfig.secondary',
          label: t('glossary:labels.secondaryFg'),
          uiProps: {
            type: 'color',
          },
        },
        {
          name: 'themeConfig.secondaryFg',
          label: t('glossary:labels.secondaryFg'),
          uiProps: {
            type: 'color',
          },
        },
        {
          name: 'themeConfig.backgroundSecondary',
          label: t('glossary:labels.backgroundSecondary'),
          uiProps: {
            type: 'color',
          },
        },
        {
          name: 'themeConfig.background',
          label: t('glossary:labels.background'),
          uiProps: {
            type: 'color',
          },
        },
        {
          name: 'themeConfig.foreground',
          label: t('glossary:labels.foreground'),
          uiProps: {
            type: 'color',
          },
        },
        {
          name: 'themeConfig.placeholder',
          label: t('glossary:labels.placeholder'),
          uiProps: {
            type: 'color',
          },
        },
        {
          name: 'themeConfig.logo',
          label: t('glossary:labels.logo'),
        },
        {
          name: 'themeConfig.logoBubble',
          label: t('glossary:labels.logoBubble'),
        },
      ],
      [t],
    );

    return (
      <Card collapsed title={t('glossary:labels.themeConfig')}>
        <SchemaFields schema={schema} />
        <Button
          variant='secondary'
          onClick={() => {
            setValue('themeConfig.primary', null);
            setValue('themeConfig.primaryFg', null);
            setValue('themeConfig.secondary', null);
            setValue('themeConfig.secondaryFg', null);
            setValue('themeConfig.backgroundSecondary', null);
            setValue('themeConfig.background', null);
            setValue('themeConfig.foreground', null);
            setValue('themeConfig.placeholder', null);
          }}
        >
          {t('glossary:actions.reset')}
        </Button>
      </Card>
    );
  },
);
