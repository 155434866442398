import type { FeedbackType } from '@kanbu/schema';
import { Rating } from '@kanbu/schema/enums';
import { IconThumbDownFilled, IconThumbUpFilled } from '@tabler/icons-react';
import { memo } from 'react';

interface FeedbackProps {
  feedback: FeedbackType;
}

const Feedback = memo(function Feedback({ feedback }: FeedbackProps) {
  return (
    <div className='w-[90%]'>
      <div className='-mt-3 ml-0 flex flex-row justify-end'>
        <div className='flex size-9 items-center justify-center rounded-xl bg-black text-white'>
          {feedback.rating === Rating.Negative && (
            <IconThumbDownFilled className='size-5' />
          )}
          {feedback.rating === Rating.Positive && (
            <IconThumbUpFilled className='size-5' />
          )}
        </div>
      </div>
      {feedback?.text && (
        <div className='-mt-7 flex w-max max-w-[85%] flex-col items-end gap-2'>
          <div className='relative flex rounded-lg bg-foreground px-3.5 py-2 text-white sm:w-fit'>
            <p>{feedback?.text}</p>
          </div>
        </div>
      )}
    </div>
  );
});

export default Feedback;
