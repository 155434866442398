import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { ChatForm } from '@/features/chats/components/ChatForm';

export const Route = createFileRoute('/_baseLayout/chats/create')({
  component: ChatsCreatePage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin, Role.TmpAdmin]),
});

export function ChatsCreatePage() {
  const { t } = useTranslation(['globals', 'chats']);

  return (
    <FormPage
      title={t('chats:texts.create')}
      breadcrumbs={[
        { label: t('globals:routeNames.chats'), to: '/chats' },
        { label: t('chats:texts.create'), to: '/chats/create' },
      ]}
    >
      <ChatForm />
    </FormPage>
  );
}
