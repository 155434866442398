import { format as dateFnsFormat, setDefaultOptions } from 'date-fns';
import { cs as dateFnsCsLocale } from 'date-fns/locale/cs';

import type { Enum } from '../types/enum';

// TODO use i18n lang codes
setDefaultOptions({
  locale: dateFnsCsLocale,
});

/**
 * Formats number to currency. For example: 1000 -> $1 000
 */
const currencyDollarFormat = new Intl.NumberFormat('cs-CZ', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 9,
});

/**
 * Formats number to currency. For example: 1000 -> 1 000 Kč
 */
const currencyFormat = new Intl.NumberFormat('cs-CZ', {
  style: 'currency',
  currency: 'CZK',
});

/**
 * Formats number to currency. For example: 1000 -> 1k Kč
 */
const currencyFormatShort = new Intl.NumberFormat('cs-CZ', {
  style: 'currency',
  currency: 'CZK',
  notation: 'compact',
  compactDisplay: 'short',
});

/**
 * Formats number to percentage. For example: 0.1 -> 10.00%
 */
const percentageFormat = new Intl.NumberFormat('cs-CZ', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Formats number to decimal. For example: 1000 -> 1 000
 */
const numberFormat = new Intl.NumberFormat('cs-CZ', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

/**
 * Parses given date to Date object. If date is undefined, returns undefined.
 */
export function parseDate<T extends Date | number | string>(
  date: T | undefined,
) {
  if (!date) {
    return;
  }

  // Return date object
  if (date instanceof Date) {
    return date;
  }

  // Parse serialized Date string
  if (typeof date === 'string' || typeof date === 'number') {
    return new Date(date);
  }

  return date as Date;
}

/**
 * Custom date formats for date-fns library. Used in `dateFormat` function.
 */
export const DateFormat = Object.freeze({
  Time: 'H:mm', // 14:30
  PreciseTime: 's.SSS', // 12.345s
  Date: `d.\u{00A0}MMMM\u{00A0}yyyy`, // 28. listopadu 2023
  DateNumeric: `d.\u{00A0}M.\u{00A0}yyyy`, // 28. 11. 2023
  DateTime: `d.\u{00A0}MMMM\u{00A0}yyyy, H:mm`, // 28. listopadu 2023, 14:30
  DateTimeNumeric: `d.\u{00A0}M.\u{00A0}yyyy, H:mm`, // 28. 11. 2023, 14:30
  DateInput: `yyyy-MM-dd`, // 2023-11-28
  MonthShort: `LLL`, // listopadu 2023
  Month: `LLLL`, // listopad
  MonthYear: `LLLL\u{00A0}yyyy`, // listopad 2023
});

/**
 * Extracts initials from given string, e.g. 'John Doe' -> 'JD'.
 */
function parseInitials(name: string | undefined | null) {
  if (!name) {
    return '';
  }

  // Extract first and last name
  const [firstName, ...rest] = name.split(' ');
  const lastName = rest.pop();

  // Extract initials
  const firstInitial = firstName.substring(0, 1).toUpperCase();
  const lastInitial = lastName ? lastName.substring(0, 1).toUpperCase() : '';

  return `${firstInitial}${lastInitial}`;
}

/**
 * Formats given date to string representation.
 */
function formatDate(
  date: Date | string | undefined,
  format: Enum<typeof DateFormat> = DateFormat.Date,
): string {
  const parsedDate = parseDate(date);

  if (!parsedDate) {
    return '';
  }

  return dateFnsFormat(parsedDate, format);
}

/**
 * Truncates given string to specified length.
 */
function truncateString(value: string | undefined, maxLength = 100) {
  if (!value) {
    return '';
  }

  if (value.length <= maxLength) {
    return value;
  }

  return `${value.substring(0, maxLength).trim()}...`;
}

/**
 * Capitalizes first letter of given string.
 */
function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Formats given data to full name.
 */
function formatName(
  data: { firstName: string; lastName: string } | undefined | null,
) {
  if (!data) {
    return '';
  }

  return `${data.firstName} ${data.lastName}`;
}

/**
 * Formats given number and unit to human readable format.
 * For example: 1000, 'watt' -> '1 kW'
 * For example: -1000, 'watt' -> '-1 kW'
 * For example: 1000000, 'watt' -> '1 MW'
 * For example: 1000000000, 'watt' -> '1 GW'
 */
function formatUnit(value: number, unit: 'W'): string {
  const absWatts = Math.abs(value);
  let unitSuffix = '';

  if (absWatts >= 1e9) {
    unitSuffix = `G${unit}`;
    value = value / 1e9;
  } else if (absWatts >= 1e6) {
    unitSuffix = `?${unit}`;
    value = value / 1e6;
  } else if (absWatts >= 1e3) {
    unitSuffix = `k${unit}`;
    value = value / 1e3;
  } else {
    unitSuffix = unit;
    value = value;
  }

  const formatter = new Intl.NumberFormat('cs-CZ', {
    style: 'decimal',
    maximumFractionDigits: 2,
  });

  return `${formatter.format(value)} ${unitSuffix}`;
}

/**
 * Collection of formatters, that can be used to format
 * multiple data for display.
 */
export const formatUtils = {
  initials: parseInitials,
  date: formatDate,
  capitalize,
  truncate: truncateString,
  fullName: formatName,
  unit: formatUnit,
  currency: (value: number) => currencyFormat.format(value),
  currencyDollar: (value: number) => currencyDollarFormat.format(value),
  currencyShort: (value: number) => currencyFormatShort.format(value),
  number: (value: number) => numberFormat.format(value),
  percentage: (value: number) =>
    percentageFormat.format(value > 10 ? value / 100 : value),
};
