import type { ThreadType } from '@kanbu/schema';
import { memo } from 'react';

import { Message } from './Message';
import { Loader } from '../loader/Loader';

export type ChatProps = {
  thread: ThreadType | undefined | null;
};

export const Chat = memo(function Chat({ thread }: ChatProps) {
  if (!thread) {
    return (
      <div className='relative size-full'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-6'>
      {thread?.messages
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        )
        .map(message => <Message message={message} key={message.id} />)}
    </div>
  );
});
