import { trpc } from '@/services/trpc';

export function useSourceDocumentMutation() {
  const utils = trpc.useUtils();

  const create = trpc.sourceDocuments.create.useMutation({
    onSuccess: async () => utils.sourceDocuments.findAll.invalidate(),
  });

  const update = trpc.sourceDocuments.update.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.sourceDocuments.findAll.invalidate(),
        utils.sourceDocuments.findOne.invalidate({ id }),
      ]),
  });

  const remove = trpc.sourceDocuments.delete.useMutation({
    onSuccess: async (data, { id }) =>
      utils.sourceDocuments.findAll.invalidate(),
  });

  return {
    create,
    update,
    remove,
  };
}
