import type { ChatType } from '@kanbu/schema';
import {
  generateEmbeddingsSchema,
  type GenerateEmbeddings,
} from '@kanbu/schema/contracts';
import { Role } from '@kanbu/schema/enums';
import { Button, Dialog, Separator, toast } from '@utima/ui';
import type { TypedFormState } from '@utima/ui-informed';
import type { FormApi } from 'informed';
import { Box, FileStack, Loader2, ScrollText } from 'lucide-react';
import { memo, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { DisplayValue } from '@/components/displayValue/DisplayValue';
import { BasicForm } from '@/components/form/BasicForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { Protected } from '@/components/protected/Protected';
import { trpc } from '@/services/trpc';

export type EmbeddingsMetaFieldsProps = {
  data: ChatType | undefined;
};

export const EmbeddingsMetaFields = memo(function EmbeddingsMetaFields({
  data,
}: EmbeddingsMetaFieldsProps) {
  const { t } = useTranslation(['glossary', 'chats']);
  const formApi = useRef<FormApi>();
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync, isPending } = trpc.embedder.generate.useMutation();

  const { data: vectorDBStatistics, isLoading: isLoadingVectorDBStatistics } =
    trpc.embedder.vectorDBStatistics.useQuery(
      {
        chatId: data?.id!,
        indexVersion: data?.embeddingsVersion!,
      },
      {
        enabled: !!data?.id,
      },
    );

  // Init default values
  const initialValues = useMemo(
    () =>
      ({
        chatId: data?.id,
        version: data?.embeddingsVersions
          ? Number.parseInt(
              data?.embeddingsVersions.split(', ')?.pop() ?? '0',
            ) + 1
          : 1,
        chunkSize: 800,
        batchSize: 5,
        chunkOverlap: 120,
      }) as GenerateEmbeddings,
    [data?.embeddingsVersions, data?.id],
  ) as unknown as GenerateEmbeddings;

  const { schema } = useFieldsSchema(
    [
      {
        name: 'version',
        label: t('glossary:labels.embeddingsVersion'),
        tooltip: t('glossary:tooltips.embeddingsVersion'),
        required: true,
        type: 'number',
      },
      {
        name: 'chunkSize',
        label: t('glossary:labels.chunkSize'),
        tooltip: t('glossary:tooltips.chunkSize'),
        required: true,
        type: 'number',
      },
      {
        name: 'batchSize',
        label: t('glossary:labels.batchSize'),
        required: true,
        type: 'number',
      },
      {
        name: 'chunkOverlap',
        label: t('glossary:labels.chunkOverlap'),
        tooltip: t('glossary:tooltips.chunkOverlap'),
        required: true,
        type: 'number',
      },
      {
        name: 'transformer',
        label: t('glossary:labels.transformer'),
        type: 'select',
        options: {
          jamu: 'JAMU',
        },
      },
      {
        name: 'chunkTransformer',
        label: t('glossary:labels.chunkTransformer'),
        type: 'select',
        options: {
          contextualized: 'Contextualized',
        },
      },
    ],
    [t],
  );

  const handleGenerateEmbeddings = async () => {
    if (!data?.id) {
      return;
    }

    try {
      await mutateAsync({
        chatId: data.id,
        version: 1,
        batchSize: 5,
        chunkSize: 800,
        chunkOverlap: 120,
        chunkTransformer: 'contextualized',
      });

      toast.success(t('chats:toasts.generateEmbeddings.success.title'), {
        description: t('chats:toasts.generateEmbeddings.success.description'),
      });

      setIsOpen(false);
    } catch (error) {
      console.error(error);
      toast.error(t('chats:toasts.generateEmbeddings.error.title'), {
        description: t('chats:toasts.generateEmbeddings.error.description'),
      });
    }
  };

  const handleSubmit = async (
    formState: TypedFormState<GenerateEmbeddings>,
  ) => {
    const { values } = formState;

    if (!values || !data?.id) {
      return;
    }

    try {
      await mutateAsync(
        generateEmbeddingsSchema.parse({
          ...values,
          chatId: data.id,
        }),
      );

      toast.success(t('chats:toasts.generateEmbeddings.success.title'), {
        description: t('chats:toasts.generateEmbeddings.success.description'),
      });

      setIsOpen(false);
    } catch (error) {
      console.error(error);
      toast.error(t('chats:toasts.generateEmbeddings.error.title'), {
        description: t('chats:toasts.generateEmbeddings.error.description'),
      });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Protected roles={[Role.SuperAdmin]}>
        <Card title='Embeddings LEGACY'>
          <div className='rounded-md bg-red-300 p-3 text-center text-sm text-red-900'>
            Všechny nové embeddings musí používat
            <br />
            Agenta <strong>RAG v2</strong>
          </div>
          <DisplayValue label={t('glossary:labels.embeddingsCount')} Icon={Box}>
            {data?.embeddingsCount ?? 0}
          </DisplayValue>
          <DisplayValue
            label={t('glossary:labels.currentEmbeddingsCount')}
            Icon={ScrollText}
          >
            {data?.currentEmbeddingsCount ?? 0}
          </DisplayValue>
          <DisplayValue
            label={t('glossary:labels.embeddingsVersions')}
            Icon={FileStack}
          >
            {data?.embeddingsVersions ?? '-'}
          </DisplayValue>
        </Card>
      </Protected>
      <Card title={t('chats:texts.embeddingsMeta')}>
        <DisplayValue label={t('glossary:labels.embeddingsCount')} Icon={Box}>
          {isLoadingVectorDBStatistics ? (
            <Loader2 className='my-1 size-4 animate-spin' />
          ) : (
            vectorDBStatistics?.totalCount || 0
          )}
        </DisplayValue>
        <DisplayValue
          label={t('glossary:labels.currentEmbeddingsCount')}
          Icon={ScrollText}
        >
          {isLoadingVectorDBStatistics ? (
            <Loader2 className='my-1 size-4 animate-spin' />
          ) : (
            vectorDBStatistics?.currentCount || 0
          )}
        </DisplayValue>
        <DisplayValue
          label={t('glossary:labels.embeddingsVersions')}
          Icon={FileStack}
        >
          {isLoadingVectorDBStatistics ? (
            <Loader2 className='my-1 size-4 animate-spin' />
          ) : (
            vectorDBStatistics?.indexVersions || '-'
          )}
        </DisplayValue>

        <Separator />

        <Protected roles={[Role.TmpAdmin]}>
          <Button
            disabled={isPending}
            loading={isPending}
            variant='primary'
            onClick={handleGenerateEmbeddings}
          >
            {t('glossary:actions.generateEmbeddings')}
          </Button>
        </Protected>

        <Protected roles={[Role.SuperAdmin]}>
          <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
            <Dialog.Trigger asChild>
              <Button variant='primary'>
                {t('glossary:actions.generateEmbeddings')}
              </Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <Dialog.Title>
                {t('chats:dialogs.generateEmbeddings.title')}
              </Dialog.Title>
              <Dialog.Description>
                {t('chats:dialogs.generateEmbeddings.description')}
              </Dialog.Description>
              <BasicForm
                zodSchema={generateEmbeddingsSchema.omit({
                  chatId: true,
                })}
                initialValues={initialValues}
                formApiRef={formApi}
                className='flex flex-col gap-4'
                onSubmit={handleSubmit}
              >
                <SchemaFields schema={schema} />
              </BasicForm>
              <Dialog.Footer>
                <Button
                  type='button'
                  disabled={isPending}
                  loading={isPending}
                  variant='secondary'
                  onClick={event => {
                    formApi.current?.submitForm();
                  }}
                >
                  {t('glossary:actions.generate')}
                </Button>
                <Dialog.Close asChild>
                  <Button variant='ghost' outline>
                    {t('glossary:actions.close')}
                  </Button>
                </Dialog.Close>
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog.Root>
        </Protected>
      </Card>
    </>
  );
});
