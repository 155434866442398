import { Role } from '@kanbu/schema/enums';
import { Menu as UIMenu } from '@utima/ui';
import {
  CircleHelp,
  MailCheck,
  UsersIcon,
  UserRound,
  MessageCircleCodeIcon,
  Building,
  Contact,
} from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuLink } from './MenuLink';
import { Protected } from '../protected/Protected';

export type MenuProps = {};

export const Menu = memo(function Menu({}: MenuProps) {
  const { t } = useTranslation(['globals']);

  return (
    <UIMenu.Root>
      <UIMenu.Content>
        <UIMenu.Item>
          <MenuLink to='/threads' Icon={MailCheck}>
            {t('globals:routeNames.threads')}
          </MenuLink>
        </UIMenu.Item>
        <UIMenu.Item>
          <MenuLink to='/chat-users' Icon={UsersIcon}>
            {t('globals:routeNames.chatUsers')}
          </MenuLink>
        </UIMenu.Item>
        <Protected roles={[Role.SuperAdmin, Role.TmpAdmin]}>
          <UIMenu.Item>
            <MenuLink to='/chats' Icon={MessageCircleCodeIcon}>
              {t('globals:routeNames.chats')}
            </MenuLink>
          </UIMenu.Item>
        </Protected>
        <UIMenu.Item>
          <MenuLink to='/faqs' Icon={CircleHelp}>
            {t('globals:routeNames.faqs')}
          </MenuLink>
        </UIMenu.Item>
        <UIMenu.Item>
          <MenuLink to='/operators' Icon={Contact}>
            {t('globals:routeNames.operators')}
          </MenuLink>
        </UIMenu.Item>
        <UIMenu.Item>
          <MenuLink to='/users' Icon={UserRound}>
            {t('globals:routeNames.users')}
          </MenuLink>
        </UIMenu.Item>
        <Protected roles={[Role.SuperAdmin]}>
          <UIMenu.Item>
            <MenuLink to='/organizations' Icon={Building}>
              {t('globals:routeNames.organizations')}
            </MenuLink>
          </UIMenu.Item>
        </Protected>
      </UIMenu.Content>
    </UIMenu.Root>
  );
});
