import { cn } from '@utima/ui';
import type { ComponentPropsWithoutRef } from 'react';

export interface FormColProps extends ComponentPropsWithoutRef<'div'> {
  last?: boolean;
}

/**
 * Form column component, used to wrap form inputs in a predefined grid
 * of 3 columns. Use meta prop for last smaller column.
 */
export function FormCol({ last, className, ...restProps }: FormColProps) {
  return (
    <div className={cn('flex flex-col gap-6', className)} {...restProps} />
  );
}
