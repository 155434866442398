import { createContext, useContext } from 'react';

export type ComboboxContextType<T = unknown> = {
  currentValue: T;
};

export const ComboboxContext = createContext<ComboboxContextType>(undefined!);

/**
 * Used to pass data from combobox to it's children, like actual value, etc.
 */
export function useComboboxContext<T = unknown>() {
  const context = useContext(ComboboxContext);

  if (!context) {
    throw new Error(
      'No ComboboxContext found when calling useComboboxContext.',
    );
  }

  return context as ComboboxContextType<T>;
}
