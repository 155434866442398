import { Input } from '@utima/ui-informed';
import { useFormState } from 'informed';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';

import { PasswordHint } from './PasswordHint';
import {
  MIN_LENGTH,
  passwordValidate,
  PasswordValidationError,
} from './passwordValidate';

export type PasswordFieldsProps = {
  hasOldPassword?: boolean;
  title?: string;
};

export function PasswordFields({
  hasOldPassword = true,
  title,
}: PasswordFieldsProps) {
  const { t } = useTranslation(['glossary', 'globals']);
  const { values } = useFormState();

  const onValidate = useCallback(
    (value: unknown, values: Record<string, unknown>) => {
      const errors = passwordValidate(
        values.password as string,
        values.confirmPassword as string,
      );

      if (errors?.includes(PasswordValidationError.LENGTH)) {
        return t('globals:passwordFields.reqLength', {
          length: MIN_LENGTH,
        });
      }

      if (errors?.includes(PasswordValidationError.MATCH)) {
        return t('globals:passwordFields.reqMatch');
      }

      if (errors?.includes(PasswordValidationError.NUMBER)) {
        return t('globals:passwordFields.reqNumber');
      }

      if (errors?.includes(PasswordValidationError.SPECIAL)) {
        return t('globals:passwordFields.reqSpecial');
      }

      if (errors?.includes(PasswordValidationError.UPPERCASE)) {
        return t('globals:passwordFields.reqUppercase');
      }
    },
    [t],
  );

  const passwordFieldsRequired = !!(
    (hasOldPassword && values.oldPassword) ||
    values.password ||
    values.confirmPassword
  );

  return (
    <>
      <Card title={title ?? t('glossary:labels.passwordChange')}>
        {hasOldPassword && (
          <Input
            type='password'
            required={passwordFieldsRequired}
            name='oldPassword'
            label={t('labels.password')}
            showOptional={false}
            passwordPreview
            showErrorIfError
          />
        )}
        <Input
          type='password'
          required={passwordFieldsRequired}
          name='password'
          label={t('glossary:labels.newPassword')}
          validate={onValidate}
          validateOn='change'
          showOptional={false}
          passwordPreview
          showErrorIfError
          validateWhen={['confirmPassword']}
        />
        <Input
          type='password'
          required={passwordFieldsRequired}
          name='confirmPassword'
          label={t('glossary:labels.confirmPassword')}
          validate={onValidate}
          validateOn='change'
          showOptional={false}
          passwordPreview
          showErrorIfError
          validateWhen={['password']}
        />
      </Card>
      <PasswordHint />
    </>
  );
}
