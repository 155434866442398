import { Role } from '@kanbu/schema/enums';
import { Badge } from '@utima/ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export type RoleBadgeProps = {
  role: Role;
};

function getRoleVariant(role: Role) {
  switch (role) {
    case Role.SuperAdmin:
      return 'danger';
    case Role.Admin:
      return 'info';

    default:
      return 'info';
  }
}

export const RoleBadge = memo(function RoleBadge({ role }: RoleBadgeProps) {
  const { t } = useTranslation('enums', {
    keyPrefix: 'role',
  });

  return (
    <Badge variant={getRoleVariant(role)} size='sm' outline>
      {t(role)}
    </Badge>
  );
});
