import type { UserType } from '@kanbu/schema';
import { createUserSchema, updateUserSchema } from '@kanbu/schema/contracts';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

import { PasswordFields } from '../../../components/fields/passwordFields/PasswordFields';
import { useUserMutation } from '../useUserMutation';

export type ProfileFormProps = {
  data: UserType;
};

export const ProfileForm = memo(function ProfileForm({
  data,
}: ProfileFormProps) {
  const { t } = useTranslation(['glossary', 'enums']);
  const {
    update: { mutateAsync: update },
  } = useUserMutation();

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'firstName',
        label: t('glossary:labels.firstName'),
        required: true,
      },
      {
        name: 'lastName',
        label: t('glossary:labels.lastName'),
        required: true,
      },
      {
        name: 'email',
        label: t('glossary:labels.email'),
        required: true,
        disabled: true,
      },
    ],
    [t],
  );

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createUserSchema}
      updateSchema={updateUserSchema.extend({
        email: z.string().email().max(255),
        confirmPassword: z.string().max(255).optional(),
      })}
      onUpdate={async values => {
        const { confirmPassword, email, ...restValues } =
          values as unknown as UserType & {
            confirmPassword: string;
            oldPassword: string;
          };

        return update(
          updateUserSchema.parse({
            ...restValues,
            id: data.id,
          }),
        );
      }}
    >
      <EntityCRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col>
        <PasswordFields />
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col>
        <MetaFields data={data} />
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
