import './services/i18n';
import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { FormTranslationsContext } from '@utima/ui-informed';
import { StrictMode, Suspense } from 'react';

import { Loader } from './components/loader/Loader';
import { useFormTranslations } from './hooks/useFormTranslations';
import { queryClient } from './services/queryClient';
import { router } from './services/router';
import { trpc, trpcQueryClient } from './services/trpc';

import './app.css';

export function App() {
  const translations = useFormTranslations();

  return (
    <StrictMode>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <trpc.Provider client={trpcQueryClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <FormTranslationsContext.Provider value={translations}>
                <RouterProvider router={router} />
              </FormTranslationsContext.Provider>
            </QueryClientProvider>
          </trpc.Provider>
        </Suspense>
      </ErrorBoundary>
    </StrictMode>
  );
}
