import { setComponentOverrides } from '@utima/ui/overrides';

/**
 * @utima/ui component overrides
 */
setComponentOverrides({
  toast: {
    toast: '[&>[data-icon]]:mt-0.5',
    error: '[&_[data-description]]:text-foreground',
    success: '[&_[data-description]]:text-foreground',
  },
});
