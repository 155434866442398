import { createContext, type ReactNode, useContext, useMemo } from 'react';

export type SidebarPaneContextType = {
  isOpen: boolean;
};

const SidebarPaneContext = createContext<SidebarPaneContextType | undefined>(
  undefined,
);

export function useSidebarPaneContext() {
  const context = useContext(SidebarPaneContext);

  if (!context) {
    throw new Error(
      'No SidebarPaneContext found when calling useSidebarPaneContext.',
    );
  }

  return context;
}

export function SidebarPaneContextProvider({
  children,
  isOpen,
}: SidebarPaneContextType & { children: ReactNode }) {
  const memoValues = useMemo(() => ({ isOpen }), [isOpen]);

  return (
    <SidebarPaneContext.Provider value={memoValues}>
      {children}
    </SidebarPaneContext.Provider>
  );
}
