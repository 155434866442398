import { formatUtils } from '@kanbu/shared';
import { Avatar, cn } from '@utima/ui';
import { useFormContext } from '@utima/ui-informed';
import type { LucideProps } from 'lucide-react';
import type { ElementType } from 'react';

type ComboboxRichItemProps = {
  description?: string;
  title: string | undefined;
  readOnly?: boolean;
  Icon?: ElementType<LucideProps>;
  readonly?: boolean;
  imgSrc?: string;
  type?: 'icon' | 'avatar';
};

export function ComboboxRichItem({
  title,
  description,
  Icon,
  readOnly: propReadOnly,
  imgSrc,
  type = 'icon',
}: ComboboxRichItemProps) {
  const formContext = useFormContext();
  const readOnly = propReadOnly ?? formContext?.readOnly;

  if (!title && readOnly) {
    return '-';
  }

  if (!title) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex flex-row gap-2 overflow-hidden items-start',
        readOnly && 'bg-white border-border border rounded-radius px-3 py-2',
      )}
    >
      {type === 'icon' &&
        (imgSrc ? (
          <div className='size-10 shrink-0 rounded-radius border border-border bg-white p-0.5'>
            <img
              src={imgSrc}
              alt={title}
              className='size-full bg-white object-contain'
            />
          </div>
        ) : Icon ? (
          <Icon className='size-4' />
        ) : null)}

      {type === 'avatar' && (
        <Avatar src={imgSrc} fallback={formatUtils.initials(title)} />
      )}

      <div className='mt-px flex w-full min-w-0 flex-col items-start justify-start gap-0.5'>
        <p className='text-sm font-semibold text-foreground'>{title}</p>
        {description && (
          <p className='w-full overflow-hidden text-ellipsis whitespace-pre text-xs text-muted-fg'>
            {description}
          </p>
        )}
      </div>
    </div>
  );
}
