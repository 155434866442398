import type { OperatorType } from '@kanbu/schema';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useOperatorsCols } from '@/features/operators/useOperatorsCols.tsx';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc.ts';

export const Route = createFileRoute('/_baseLayout/operators/')({
  component: OperatorsPage,
});

export function OperatorsPage() {
  const { t } = useTranslation(['globals', 'operators']);
  const columns = useOperatorsCols();
  const handleQuery = useTableQuery(trpcClient.operators.findAll.query);
  const handleRow = useDetailRowHandler<OperatorType>(row => ({
    to: '/operators/$operatorId/edit',
    params: {
      operatorId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t('operators:texts.manage')}
      breadcrumbs={[
        { label: t('globals:routeNames.operators'), to: '/operators' },
      ]}
      createButton={{
        to: '/operators/create',
        label: t('operators:actions.create'),
      }}
      columns={columns}
      queryKey={getQueryKey(trpc.operators.findAll)}
      onQueryFn={handleQuery}
      onRow={handleRow}
    />
  );
}
