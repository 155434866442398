import type { ChatType } from '@kanbu/schema';
import {
  createEmbeddingsSchema,
  type CreateEmbeddings,
} from '@kanbu/schema/contracts';
import { Button, Dialog, Separator, toast } from '@utima/ui';
import type { TypedFormState } from '@utima/ui-informed';
import type { FormApi } from 'informed';
import { FileBoxIcon, File } from 'lucide-react';
import { memo, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { DisplayValue } from '@/components/displayValue/DisplayValue';
import { BasicForm } from '@/components/form/BasicForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { AppSettings } from '@/constants/AppSettings';
import { trpc } from '@/services/trpc';

export type SourceDocumentsMetaFieldsProps = {
  data: ChatType | undefined;
};

type SourceDocumentMetaFormState = Omit<CreateEmbeddings, 'chatId'>;

export const SourceDocumentsMetaFields = memo(
  function SourceDocumentsMetaFields({ data }: SourceDocumentsMetaFieldsProps) {
    const { t } = useTranslation(['glossary', 'chats']);
    const formApi = useRef<FormApi>();
    const [isOpen, setIsOpen] = useState(false);
    const { mutateAsync, isPending } = trpc.embedder.create.useMutation();
    const { data: folders } = trpc.storage.listFolders.useQuery();

    // Init default values
    const initialValues = useMemo(
      () =>
        ({
          limit: 100,
          batchSize: 5,
          bucketName: AppSettings.api.baseURL.includes('kanbu.ai')
            ? 'kanbu-storage'
            : 'kanbu-storage-dev',
        }) as Partial<SourceDocumentMetaFormState>,
      [],
    ) as unknown as SourceDocumentMetaFormState;

    const { schema } = useFieldsSchema(
      [
        {
          name: 'mode',
          label: t('glossary:labels.mode'),
          required: true,
          type: 'select',
          options: {
            storage: 'storage',
            scrape: 'scrape',
            crawl: 'crawl',
          },
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              ['scrape', 'storage'].includes(
                (formState as TypedFormState<SourceDocumentMetaFormState>)
                  .values.mode,
              ),
            $fields: [
              {
                name: 'batchSize',
                label: t('glossary:labels.batchSize'),
                required: true,
                type: 'number',
              },
            ],
          },
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              (formState as TypedFormState<SourceDocumentMetaFormState>).values
                .mode === 'storage',
            $fields: [
              {
                name: 'bucketName',
                label: t('glossary:labels.bucketName'),
                required: true,
                type: 'text',
              },
              {
                name: 'prefix',
                label: t('chats:labels.prefix'),
                required: true,
                type: 'select',
                options: folders?.reduce(
                  (acc, folder) => {
                    acc[folder] = folder.split('/').pop() ?? folder;

                    return acc;
                  },
                  {} as Record<string, string>,
                ),
              },
            ],
          },
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              (formState as TypedFormState<SourceDocumentMetaFormState>).values
                .mode === 'crawl',
            $fields: [
              {
                name: 'url',
                label: t('glossary:labels.url'),
                placeholder: 'https://example.com',
                required: true,
                type: 'text',
              },
              {
                name: 'limit',
                label: t('glossary:labels.limit'),
                required: true,
                type: 'number',
              },
            ],
          },
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              (formState as TypedFormState<SourceDocumentMetaFormState>).values
                .mode === 'scrape',
            $fields: [
              {
                name: 'urls',
                label: t('glossary:labels.urls'),
                tooltip: t('chats:tooltips.loadSourceDocumentsUrls'),
                placeholder: 'https://example.com',
                required: true,
                type: 'text',
                uiControl: 'textarea',
                uiProps: {
                  rows: 20,
                },
              },
            ],
          },
        },
      ],
      [folders, t],
    );

    const handleSubmit = async (
      formState: TypedFormState<SourceDocumentMetaFormState>,
    ) => {
      const { values } = formState;

      if (!values || !data?.id) {
        return;
      }

      try {
        const requestData = {
          chatId: data?.id,
          ...values,
        };

        // Not sure how to properly handle union types in zod
        if (values.mode === 'scrape') {
          (requestData as any).urls = (values as any).urls?.split('\n');
        }

        // Request source data generation
        await mutateAsync(createEmbeddingsSchema.parse(requestData));

        toast.success(t('chats:toasts.generateEmbeddings.success.title'), {
          description: t('chats:toasts.generateEmbeddings.success.description'),
        });

        setIsOpen(false);
      } catch (error) {
        console.error(error);
        toast.error(t('chats:toasts.generateEmbeddings.error.title'), {
          description: t('chats:toasts.generateEmbeddings.error.description'),
        });
      }
    };

    if (!data) {
      return null;
    }

    return (
      <Card title={t('chats:texts.sourceDocumentsMeta')}>
        <DisplayValue label={t('glossary:labels.documentsCount')} Icon={File}>
          {data?.documentsCount ?? 0}
        </DisplayValue>
        <DisplayValue
          label={t('glossary:labels.documentPartsCount')}
          Icon={FileBoxIcon}
        >
          {data?.documentPartsCount ?? 0}
        </DisplayValue>
        <Separator />

        <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
          <Dialog.Trigger asChild>
            <Button variant='primary'>
              {t('glossary:actions.loadSourceDocuments')}
            </Button>
          </Dialog.Trigger>
          <Dialog.Content>
            <Dialog.Title>
              {t('chats:dialogs.loadSourceDocuments.title')}
            </Dialog.Title>
            <Dialog.Description>
              {t('chats:dialogs.loadSourceDocuments.description')}
            </Dialog.Description>
            <BasicForm
              initialValues={initialValues}
              formApiRef={formApi}
              className='flex flex-col gap-4'
              onSubmit={handleSubmit}
            >
              <SchemaFields schema={schema} />
            </BasicForm>
            <Dialog.Footer>
              <Button
                type='button'
                disabled={isPending}
                loading={isPending}
                variant='secondary'
                onClick={event => {
                  formApi.current?.submitForm();
                }}
              >
                {t('glossary:actions.process')}
              </Button>
              <Dialog.Close asChild>
                <Button variant='ghost' outline>
                  {t('glossary:actions.close')}
                </Button>
              </Dialog.Close>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog.Root>
      </Card>
    );
  },
);
