import type { OperatorType } from '@kanbu/schema';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';

import { useOperatorMutation } from './useOperatorMutation';

const columnHelper = createColumnHelper<OperatorType>();

export function useOperatorsCols() {
  const { t } = useTranslation(['glossary']);
  const {
    remove: { mutateAsync: remove },
  } = useOperatorMutation();

  return useMemo(
    () => [
      columnHelper.accessor(row => row.firstName, {
        id: 'firstName',
        header: t('glossary:labels.firstName'),
        meta: {
          sortable: 'firstName',
        },
      }),
      columnHelper.accessor(row => row.lastName, {
        id: 'lastName',
        header: t('glossary:labels.lastName'),
        meta: {
          sortable: 'lastName',
        },
      }),
      columnHelper.accessor(row => row.email, {
        id: 'email',
        header: t('glossary:labels.email'),
        meta: {
          sortable: 'email',
        },
      }),
      columnHelper.accessor(row => row.phone, {
        id: 'phone',
        header: t('glossary:labels.phone'),
        meta: {
          sortable: 'phone',
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: t('glossary:labels.actions'),
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })} />
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [t, remove],
  );
}
