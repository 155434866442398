import type { Role } from '@kanbu/schema/enums';
import type { ReactNode } from '@tanstack/react-router';
import { memo } from 'react';

import { useBoundStore } from '@/store/store';

export type ProtectedProps = {
  roles: Role[];
  children: ReactNode;
};

/**
 * Component that checks if the user is authorized to access the route.
 * If the user is not authorized, it returns null.
 * If the user is authorized, it returns the children.
 */
export const Protected = memo(function Protected({
  roles,
  children,
}: ProtectedProps) {
  const user = useBoundStore(state => state.user);

  if (!user) {
    return null;
  }

  if (!roles.includes(user.role)) {
    return null;
  }

  return children;
});
