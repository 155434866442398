import type { AppRouter } from '@kanbu/admin-api/src/services/appRouter';
import { refreshTokenLink } from '@pyncz/trpc-refresh-token-link';
import type { TRPCLink } from '@trpc/client';
import {
  type CreateTRPCReact,
  createTRPCReact,
  httpBatchLink,
  createTRPCClient,
  createTRPCQueryUtils,
  unstable_httpBatchStreamLink,
  splitLink,
} from '@trpc/react-query';

import { AppSettings } from '@/constants/AppSettings';

import { queryClient } from './queryClient';
import { useBoundStore } from '../store/store';

export const trpc: CreateTRPCReact<AppRouter, unknown> =
  createTRPCReact<AppRouter>();

const httpLink = httpBatchLink({
  url: `${AppSettings.api.baseURL}/trpc`,
  headers() {
    return {
      ...(useBoundStore.getState().jwt
        ? { Authorization: 'Bearer ' + useBoundStore.getState().jwt }
        : {}),
    };
  },
  fetch(url, options) {
    return fetch(url, {
      ...options,
      credentials: 'include',
    });
  },
  transformer: undefined,
});

const streamLink = unstable_httpBatchStreamLink({
  url: `${AppSettings.api.baseURL}/trpc`,
  headers() {
    return {
      ...(useBoundStore.getState().jwt
        ? { Authorization: 'Bearer ' + useBoundStore.getState().jwt }
        : {}),
    };
  },
  fetch(url, options) {
    return fetch(url, {
      ...options,
      credentials: 'include',
    });
  },
  transformer: undefined,
});

// refreshing token needs to be done with a different client without the refresh token link
export const trpcWithoutRefreshLink = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${AppSettings.api.baseURL}/trpc`,
      headers() {
        return {
          AccessControlAllowCredentials: 'true',
        };
      },
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include',
        });
      },
    }),
  ],
});

// TODO duplicate fixme
export const trpcClient = createTRPCClient<AppRouter>({
  links: [
    // @ts-expect-error FIXME
    refreshTokenLink({
      getRefreshToken: () => {
        // our token is stored in httpsOnly cookie, but we use user email to verify the user
        return useBoundStore.getState().user?.email;
      },
      fetchJwtPairByRefreshToken: refreshData => {
        // TODO refresh
        // return trpcWithoutRefreshLink.auth.refreshToken.query({
        //   email: refreshData,
        // });
        return {} as never;
      },
      onJwtPairFetched: payload => {
        useBoundStore.getState().setJwt(payload.access);
      },
      onRefreshFailed: () => {
        // if the refresh token is invalid, we should log out the user
        useBoundStore.getState().logout();
      },
    }) as TRPCLink<AppRouter>, //this is safe only because we don't use transformer, more here https://trpc.io/docs/migrate-from-v10-to-v11#transformers-are-moved-to-links-breaking
    splitLink({
      condition: op => {
        return op.path.startsWith('evaluation.evaluate');
      },
      true: streamLink,
      false: httpLink,
    }),
  ],
});

export const trpcQueryClient = trpc.createClient({
  links: [
    // @ts-expect-error FIXME
    refreshTokenLink({
      getRefreshToken: () => {
        // our token is stored in httpsOnly cookie, but we use user email to verify the user
        return useBoundStore.getState().user?.email;
      },
      fetchJwtPairByRefreshToken: refreshData => {
        // TODO refresh
        // return trpcWithoutRefreshLink._auth.refreshToken.query({
        //   email: refreshData,
        // });
        return {} as never;
      },
      onJwtPairFetched: payload => {
        useBoundStore.getState().setJwt(payload.access);
      },
      onRefreshFailed: () => {
        // if the refresh token is invalid, we should log out the user
        useBoundStore.getState().logout();
      },
    }) as TRPCLink<AppRouter>, //this is safe only because we don't use transformer, more here https://trpc.io/docs/migrate-from-v10-to-v11#transformers-are-moved-to-links-breaking
    splitLink({
      condition: op => {
        return op.path.startsWith('evaluation.evaluate');
      },
      true: streamLink,
      false: httpLink,
    }),
  ],
});

/**
 * Create client utils we can use for invalidation and other stuff.
 */
export const trpcUtils = createTRPCQueryUtils({
  queryClient: queryClient,
  client: trpcClient,
});
