import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { Command, Popover, SelectPrimitive } from '@utima/ui';
import {
  useState,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type ReactNode,
  useEffect,
  useCallback,
  type RefObject,
  useMemo,
  type ForwardRefExoticComponent,
  type RefAttributes,
  Fragment,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import type { Primitive } from 'type-fest';

import { ComboboxGroup } from './ComboboxGroup';
import { ComboboxContext } from './useComboboxContext';

/**
 * Type for data provider factory. It should return query function and cache key factory.
 */
export type DataProviderFactory<TData, TValue> = () => {
  query: (query: {
    pageSize: number;
    search: string;
    value: TValue;
  }) => Promise<{
    items: TData[] | undefined;
    total: number;
  }>;
  cacheKeyFactory: (params: {
    pageSize: number;
    search: string;
  }) => readonly unknown[];
};

/**
 * Props for trigger render function. Use it to render something else
 * in the trigger than Select component.
 */
export type ComboboxTriggerProps<TValue, TData> = {
  value: TValue;
  item: TData | undefined;
  placeholder?: string;
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
  variant?: ComponentPropsWithoutRef<typeof SelectPrimitive.Element>['variant'];
};

/**
 * Props for optional renderValue function. Use it to render something else
 * in the trigger than just the value (e.g. render rich value with icon, etc.)
 */
export type RenderValueProps<TValue, TData> = {
  value: TValue;
  item: TData;
  placeholder?: string;
};

/**
 * Props for children render function. It provides data for
 * each item in the list.
 */
export type RenderChildrenProps<TValue, TData> = {
  item: TData;
  array: TData[];
  index: number;
  onSelect: (
    value: TValue | undefined,
    item: TData | undefined,
    prevValue: TValue | undefined,
  ) => void;
};

export type ComboboxProps<TData, TValue> = {
  id?: string;
  /**
   * Placeholder for the select trigger component.
   */
  placeholder?: string;
  /**
   * Placeholder for the search input.
   */
  searchPlaceholder?: string;
  /**
   * Like in Select, value that is the result of selection (usually ID, or some key).
   * It can be undefined, if no value is selected. It should be controlled by outside
   * component.
   */
  value: TValue;
  /**
   * Initial data, use this to provide initial data for selected value.
   */
  initialData?: TData;
  /**
   * Variant of the Select component.
   */
  variant?: ComponentPropsWithoutRef<typeof SelectPrimitive.Element>['variant'];
  /**
   * Message to show when no data are available.
   */
  emptyMessage?: string;
  /**
   * Message to show when async query is loading and no data are available.
   */
  loadingMessage?: string;
  /**
   * Limit of items fetched when async query is used.
   */
  limit?: number;
  /**
   * Debounced delay for search input (in ms).
   */
  searchDebounce?: number;
  disabled?: boolean;
  readOnly?: boolean;
  /**
   * Render separator between items in the list. Usefull for rich items
   * for better visibility.
   */
  showSeparator?: boolean;
  /**
   * Custom filter function for combobox search.
   */
  filter?: (value: string, searchString: string) => number;
  /**
   * Should filter items based on the search string.
   */
  shouldFilter?: boolean;
  /**
   * Provide custom function handler to update managed value.
   */
  onValueChange?: (
    value: TValue | undefined,
    item: TData | undefined,
    prevValue: TValue | undefined,
  ) => void;
  /**
   * Event handler called when popover open state changes.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Event handler called when auto-focusing on close. Can be prevented.
   */
  onCloseAutoFocus?: (event: Event) => void;
  /**
   * Use to render custom value instead of `value` in the trigger.
   */
  renderValue?: (props: RenderValueProps<TValue, TData>) => ReactNode;
  /**
   * Data provider factory, it should return query function and cache key factory.
   * The array function can return static data when desired or fetch async.
   *
   * For static data, just return resolved promise and use [] as query key,
   * so the cache never changes.
   */
  dataProviderFactory: DataProviderFactory<TData, TValue>;
  /**
   * Use to render custom trigger element instead of `Select` component.
   */
  Trigger?: ForwardRefExoticComponent<
    Omit<ComboboxTriggerProps<TValue, TData>, 'ref'> &
      RefAttributes<HTMLButtonElement>
  >;
  /**
   * Children equals to render function for rendering data items.
   */
  children: (props: RenderChildrenProps<TValue, TData>) => ReactNode;
  triggerRef?: RefObject<ElementRef<typeof Popover.Trigger>>;
};

/**
 * Default function for filtering items based on the search string.
 */
function defaultFilter(value: string, searchString: string) {
  return value.toLowerCase().includes(searchString.toLowerCase()) ? 1 : 0;
}

/**
 * Combobox component, which is a wrapper around the `Command` and `Popover`
 * components. It provides a searchable list of items, which can be selected.
 *
 * @example
 * <Combobox.Root
 *   value={value}
 *   onQueryFn={handleQuery}
 *   onValueChange={setValue}
 *   queryKey={entityKeys.lists()}
 *   placeholder='Vyberte značku'
 *   renderValue={({ item }) => item.name}
 * >
 *   {({ item, onSelect }) => (
 *     <Combobox.Item
 *       key={item.id}
 *       onSelect={onSelect}
 *       item={item}
 *       value={item.id}
 *       searchValue={item.name}
 *     >
 *       {item.name}
 *     </Combobox.Item>
 *   )}
 * </Combobox.Root>
 */
export function Combobox<
  TData,
  TValue extends Exclude<Primitive, symbol | bigint>,
>({
  id,
  placeholder,
  searchPlaceholder,
  value,
  variant,
  emptyMessage,
  loadingMessage,
  triggerRef,
  initialData,
  searchDebounce = 500,
  limit = 50,
  showSeparator = false,
  disabled,
  readOnly,
  Trigger,
  children,
  filter = defaultFilter,
  shouldFilter = true,
  renderValue,
  dataProviderFactory,
  onValueChange,
  onOpenChange,
  onCloseAutoFocus,
}: ComboboxProps<TData, TValue>) {
  const { t } = useTranslation('globals');
  const total = useRef<number>(0);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [queryEnabled, setQueryEnabled] = useState(true);
  const [selected, setSelected] = useState<TData | undefined>(initialData);

  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, searchDebounce);

  /**
   * Handle data fetching using on onQuery request handler
   * and react-query. We have to type-guard since the combobox
   * can be async and sync.
   */
  const { cacheKeyFactory, query } = dataProviderFactory();
  const queryResult = useQuery({
    queryKey: cacheKeyFactory({
      pageSize: limit,
      search: debouncedSearchTerm,
    }),
    queryFn: async () =>
      query({
        pageSize: limit,
        search: debouncedSearchTerm,
        value,
      }),
    placeholderData: keepPreviousData,
    staleTime: 60 * 1000 * 1000, // 60 minutes
    enabled: queryEnabled,
  });

  /**
   * Reset selected item when value is changed to undefined.
   */
  useEffect(() => {
    if (value === undefined) {
      setSelected(undefined);
    }
  }, [value]);

  /**
   * Track total number of items, always save the highest value
   * to track total number of items (even though this is usually
   * on the first load).
   *
   * Also disable/enable query based on the total number of items,
   * to improve performance and prevent unnecessary requests.
   */
  useEffect(() => {
    const newTotal = queryResult?.data?.total ?? 0;
    // total.current = newTotal > total.current ? newTotal : total.current;
    total.current = newTotal;

    // TODO FIXME!! nefunguje
    // setQueryEnabled(
    //   queryResult?.data?.total && limit >= queryResult?.data?.total
    //     ? false
    //     : true,
    // );
  }, [queryResult.data, limit]);

  /**
   * Handle open change and calls the onOpenChange callback.
   */
  const handleOpenChange = useCallback(
    (nextOpen: boolean) => {
      setOpen(nextOpen);
      onOpenChange?.(nextOpen);
    },
    [onOpenChange],
  );

  /**
   * Handle value change and closes the popover.
   */
  const handleValueChange = useCallback(
    (
      value: TValue | undefined,
      item: TData | undefined,
      prevValue: TValue | undefined,
    ) => {
      setSelected(item);
      onValueChange?.(value, item, prevValue);
      handleOpenChange(false);
    },
    [onValueChange, handleOpenChange],
  );

  const contextValue = useMemo(
    () => ({
      currentValue: value,
    }),
    [value],
  );

  /**
   * Set the loading state based on the debounced search term and
   * the isFetching state from react-query. This ensures that
   * loading indicator starts as soon as user starts typing
   * even though the request awaits debouncedSearchTerm.
   */
  useEffect(() => {
    setLoading(queryResult.isFetching);
  }, [debouncedSearchTerm, queryResult.isFetching]);

  return (
    <Popover.Root open={open} onOpenChange={handleOpenChange}>
      <Popover.Trigger asChild>
        {Trigger ? (
          <Trigger
            id={id}
            value={value}
            ref={triggerRef}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            variant={variant}
            item={selected}
          />
        ) : (
          <SelectPrimitive.Element
            id={id}
            disabled={disabled || readOnly}
            ref={triggerRef}
            variant={variant}
            className='h-auto gap-1 text-left'
          >
            {selected && renderValue
              ? renderValue({ value, item: selected })
              : value || placeholder}
          </SelectPrimitive.Element>
        )}
      </Popover.Trigger>
      <Popover.Content
        className='w-auto p-0'
        onCloseAutoFocus={onCloseAutoFocus}
      >
        <Command.Root
          className='w-0 min-w-[var(--radix-popper-anchor-width)]'
          filter={filter}
          shouldFilter={shouldFilter}
        >
          <ComboboxContext.Provider value={contextValue}>
            <Command.Input
              data-testid='combobox-input'
              value={search}
              loading={queryEnabled ? loading : false}
              placeholder={searchPlaceholder}
              onValueChange={searchString => {
                setSearch(searchString);
                setLoading(true);
              }}
            />
            <Command.List className='max-h-[500px]'>
              <Command.Empty
                loading={queryEnabled ? loading : false}
                loadingMessage={loadingMessage ?? t('combobox.loadingMessage')}
                emptyMessage={emptyMessage ?? t('combobox.emptyMessage')}
              />
              <ComboboxGroup total={total.current}>
                {(queryResult?.data?.items ?? [])?.map((item, index, array) => (
                  <Fragment key={(item as any)?.id ?? index}>
                    {children({
                      item,
                      array,
                      index,
                      onSelect: handleValueChange,
                    })}
                    {showSeparator && array.length !== index + 1 && (
                      <Command.Separator className='my-1.5' />
                    )}
                  </Fragment>
                ))}
              </ComboboxGroup>
            </Command.List>
          </ComboboxContext.Provider>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  );
}
