import { CircleCheck, CircleDashed, CircleX } from 'lucide-react';
import { memo } from 'react';

import type { PasswordValidationError } from './passwordValidate';

type ValidationIconProps = {
  targetValidationError: PasswordValidationError;
  validationErrors?: PasswordValidationError[];
};

export const ValidationIcon = memo(function ({
  validationErrors,
  targetValidationError,
}: ValidationIconProps) {
  if (!validationErrors) {
    return <CircleDashed className='size-4 shrink-0 text-foreground' />;
  }

  if (validationErrors.includes(targetValidationError)) {
    return <CircleX className='size-4 shrink-0 text-danger' />;
  }

  return <CircleCheck className='size-4 shrink-0 text-success' />;
});
