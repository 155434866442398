import { formatUtils } from '@kanbu/shared';
import { CalendarClock, CalendarPlus } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../card/Card';
import { DisplayValue } from '../displayValue/DisplayValue';

export type MetaFieldsProps = {
  children?: React.ReactNode;
  data:
    | {
        createdAt?: string;
        updatedAt?: string;
      }
    | undefined;
};

/**
 * Card component used in third column of form, to display meta fields.
 */
export const MetaFields = memo(function MetaFields({
  data,
  children,
}: MetaFieldsProps) {
  const { t } = useTranslation(['glossary']);
  const { createdAt, updatedAt } = data ?? {};

  if (!createdAt || !updatedAt) {
    return null;
  }

  return (
    <Card title={t('glossary:labels.information')}>
      {createdAt && (
        <DisplayValue
          label={t('glossary:labels.createdAt')}
          Icon={CalendarPlus}
        >
          {formatUtils.date(createdAt)}
        </DisplayValue>
      )}
      {updatedAt && (
        <DisplayValue
          label={t('glossary:labels.updatedAt')}
          Icon={CalendarClock}
        >
          {formatUtils.date(updatedAt)}
        </DisplayValue>
      )}
      {children}
    </Card>
  );
});
