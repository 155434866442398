import type { UserType } from '@kanbu/schema';
import type { StateCreator } from 'zustand';

import { queryClient } from '@/services/queryClient';

export type UserSlice = {
  jwt: string | null;
  user: null | UserType;
  setJwt: (jwt: string | null) => void;
  setUser: (user: UserType | null) => void;
  organizationId: string | null;
  setOrganizationId: (organizationId: string | null) => void;
  logout: () => void;
};

export const userSlice: StateCreator<UserSlice> = set => ({
  jwt: null,
  user: null,
  setJwt: jwt => set({ jwt }),
  setUser: user => set({ user }),
  organizationId: null,
  setOrganizationId: organizationId => set({ organizationId }),
  logout: () => {
    // Clear the query cache
    queryClient.clear();

    // Clear the user state
    set({
      jwt: null,
      user: null,
      organizationId: null,
    });
  },
});
