import type { ChatType } from '@kanbu/schema';
import { createChatSchema, updateChatSchema } from '@kanbu/schema/contracts';
import { Agent, ChatModel, Role } from '@kanbu/schema/enums';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { Protected } from '@/components/protected/Protected';
import { useAccess } from '@/hooks/useAccess';

import { AgentConfigFields } from './formFields/AgentConfigFields';
import { ChatbotScriptFields } from './formFields/ChatbotScriptFields';
import { ChatConfigFields } from './formFields/ChatConfigFields';
import { ChatThemeFields } from './formFields/ChatThemeFields';
import { EmbeddingsMetaFields } from './formFields/EmbeddingsMetaFields';
import { EvaluationFields } from './formFields/EvaluationFields';
import { SourceDocumentsMetaFields } from './formFields/SourceDocumentsMetaFields';
import { useChatMutation } from '../useChatMutation';

export type ChatFormProps = {
  data?: ChatType;
};

export const ChatForm = memo(function ChatForm({ data }: ChatFormProps) {
  const { hasAccess } = useAccess();
  const { t } = useTranslation(['glossary', 'enums']);
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useChatMutation();

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'name',
        label: t('glossary:labels.name'),
        required: true,
      },
      {
        name: 'agent',
        visible: hasAccess([Role.SuperAdmin]),
        label: t('glossary:labels.agent'),
        type: 'select',
        options: optionsFromEnum(Agent, key => t(`enums:agent.${key}`)),
      },
      {
        name: 'model',
        visible: hasAccess([Role.SuperAdmin]),
        label: t('glossary:labels.model'),
        type: 'select',
        options: optionsFromEnum(ChatModel, key => t(`enums:chatModel.${key}`)),
      },
      {
        name: 'embeddingsVersion',
        visible: hasAccess([Role.SuperAdmin]),
        label: t('glossary:labels.embeddingsVersion'),
        type: 'number',
      },
    ],
    [t, hasAccess],
  );

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createChatSchema}
      updateSchema={updateChatSchema}
      onCreate={create}
      onUpdate={update}
      onRemove={remove}
      basePath='/chats'
      idParamName='chatId'
    >
      <EntityCRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
        <AgentConfigFields />
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col>
        <ChatConfigFields />
        <ChatThemeFields />
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col>
        <MetaFields data={data} />
        <EmbeddingsMetaFields data={data} />
        <SourceDocumentsMetaFields data={data} />
        <Protected roles={[Role.SuperAdmin]}>
          <EvaluationFields data={data} />
        </Protected>
        <ChatbotScriptFields data={data} />
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
