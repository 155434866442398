import { createFileRoute, Outlet } from '@tanstack/react-router';

import bgGradient from '@/assets/images/bg-gradient.webp';
import loginMockup from '@/assets/images/login-mockup.webp';
import kanbuLogo from '@/assets/images/logo-black-small.svg';

export const Route = createFileRoute('/_authLayout')({
  component: AuthLayout,
});

export function AuthLayout() {
  return (
    <div className='flex h-screen flex-col bg-background 2xl:flex-row'>
      <div className='absolute z-10 block size-full 2xl:relative 2xl:w-1/2'>
        <div className='relative flex h-screen items-center justify-center 2xl:justify-end'>
          <img
            src={bgGradient}
            alt='Kanbu'
            className='size-full object-cover'
          />
          <img
            src={loginMockup}
            alt='Kanbu'
            className='absolute hidden w-full max-w-[560px] px-12 py-6 2xl:mr-44 2xl:block'
          />
        </div>
      </div>
      <div className='relative z-20 flex size-full w-full items-center justify-center px-12 2xl:w-1/2 2xl:justify-start 2xl:bg-slate-100 2xl:pl-44'>
        <div className='relative flex w-full max-w-[520px] flex-col items-center rounded-lg bg-white p-16 shadow-lg'>
          <div className='mb-2 size-20'>
            <img src={kanbuLogo} alt='ff-logo' className='size-full' />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
