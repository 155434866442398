import type { OrganizationType } from '@kanbu/schema';
import { createSearchExpression } from '@kanbu/schema/shared';
import { getQueryKey } from '@trpc/react-query';
import { Building2, Loader2 } from 'lucide-react';
import { memo } from 'react';

import {
  Combobox,
  type DataProviderFactory,
} from '@/components/combobox/Combobox';
import { ComboboxItem } from '@/components/combobox/ComboboxItem';
import { trpc, trpcClient } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export const OrganizationSwitch = memo(function OrganizationSwitch() {
  const { user, setJwt, setOrganizationId, organizationId } = useBoundStore(
    state => ({
      user: state.user,
      setJwt: state.setJwt,
      organizationId: state.organizationId,
      setOrganizationId: state.setOrganizationId,
    }),
  );

  if (!user) {
    return null;
  }

  const currentOrgId = organizationId ?? user.organization.id;
  const handleChange = async (value: string | undefined) => {
    if (!value) {
      return;
    }

    // Switch to new organization
    const jwt = await trpcClient.auth.switchOrganization.mutate({ id: value });

    // Update access token
    setJwt(jwt.access);

    // Update organization ID
    setOrganizationId(value);

    // Reload page
    window.location.pathname = '/';
  };

  /**
   * We need to fetch the organization to display the name in the combobox.
   * We need to fetch the data separately, since we probably don't have
   * the selected organization in the state.
   */
  const { data, isLoading } = trpc.organizations.findOne.useQuery(
    { id: currentOrgId },
    { enabled: !!currentOrgId, staleTime: Number.POSITIVE_INFINITY },
  );

  /**
   * Data provider for fetching and filtering organizations.
   */
  const dataProvider: DataProviderFactory<OrganizationType, string> = () => ({
    query: async ({ pageSize, search }) => {
      const data = await trpcClient.organizations.findAll.query({
        pagination: {
          pageSize,
        },
        where: {
          searchable: { $like: createSearchExpression(search) },
        },
      });

      return {
        items: data.items,
        total: data.meta.total,
      };
    },
    cacheKeyFactory: params => [
      ...getQueryKey(trpc.organizations.findAll),
      params,
    ],
  });

  return (
    <div className='w-60'>
      <div className='flex flex-row items-center rounded-radius border border-border bg-white'>
        <span className='flex h-[36px] items-center border-r px-2'>
          <Building2 className='size-4 text-primary' />
        </span>
        <div className='w-full [&_*]:border-none'>
          {isLoading ? (
            <div className='mx-2 flex items-center justify-end'>
              <Loader2 className='size-4 animate-spin text-primary' />
            </div>
          ) : (
            <Combobox
              value={currentOrgId}
              dataProviderFactory={dataProvider}
              onValueChange={handleChange}
              renderValue={({ item }) => item.name}
              initialData={data}
              limit={100}
            >
              {({ item, onSelect }) => (
                <ComboboxItem
                  key={item.id}
                  item={item}
                  value={item.id}
                  onSelect={onSelect as any}
                  searchValue={item.name}
                  className='w-full'
                >
                  {item.name}
                </ComboboxItem>
              )}
            </Combobox>
          )}
        </div>
      </div>
    </div>
  );
});
