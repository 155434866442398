import { Link, useNavigate } from '@tanstack/react-router';
import { Select, SelectItem, Pagination as PaginationUI } from '@utima/ui';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { usePagination } from '@/hooks/usePagination';

import type { TableConsumerProps } from './dataTableTypes';

export type PaginationProps<TData> = Pick<TableConsumerProps<TData>, 'table'>;

export function Pagination<TData>({ table }: PaginationProps<TData>) {
  const navigate = useNavigate();
  const { t } = useTranslation(['glossary', 'globals']);
  const { pageSize, pageIndex } = table.getState().pagination;
  const { pagination, hasNext, hasPrevious } = usePagination(
    pageIndex,
    table.getPageCount(),
  );

  return (
    <div className='mt-5 flex flex-col items-end justify-between gap-4 sm:flex-row sm:items-center'>
      <PaginationUI.Root size='sm' className='mx-0 w-auto'>
        <PaginationUI.Content className='flex-wrap'>
          <PaginationUI.Item>
            <PaginationUI.Link asChild disabled={!hasPrevious}>
              <Link
                disabled={!hasPrevious}
                className='flex items-center gap-1'
                preload={false}
                search={old =>
                  ({
                    ...old,
                    page: pageIndex,
                    pageSize,
                  }) as never
                }
              >
                <ChevronLeft className='size-4' />
                {t('glossary:actions.previous')}
              </Link>
            </PaginationUI.Link>
          </PaginationUI.Item>
          {pagination.map((page, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <PaginationUI.Item key={`${page}${index}`}>
              {page === '...' ? (
                <PaginationUI.Ellipsis />
              ) : (
                <PaginationUI.Link active={page === pageIndex + 1} asChild>
                  <Link
                    preload={false}
                    search={old =>
                      ({
                        ...old,
                        page: page,
                        pageSize,
                      }) as never
                    }
                  >
                    {page}
                  </Link>
                </PaginationUI.Link>
              )}
            </PaginationUI.Item>
          ))}
          <PaginationUI.Item>
            <PaginationUI.Link asChild disabled={!hasNext}>
              <Link
                disabled={!hasNext}
                className='flex items-center gap-1'
                preload={false}
                search={old =>
                  ({
                    ...old,
                    page: pageIndex + 2,
                    pageSize,
                  }) as never
                }
              >
                {t('glossary:actions.next')}
                <ChevronRight className='size-4' />
              </Link>
            </PaginationUI.Link>
          </PaginationUI.Item>
        </PaginationUI.Content>
      </PaginationUI.Root>

      <div className='flex items-center gap-2 text-sm text-primary'>
        <div className='w-32'>
          <Select
            value={pageSize.toString()}
            onValueChange={value => {
              navigate({
                search: prev =>
                  ({
                    ...prev,
                    page: 1,
                    pageSize: Number(value),
                  }) as never,
              });
            }}
          >
            {[10, 25, 50].map(pageSize => (
              <SelectItem key={pageSize} value={pageSize.toString()}>
                {pageSize} {t('globals:dataTable.items')}
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
