import { Button, Tooltip } from '@utima/ui';
import { Trash2 } from 'lucide-react';
import { memo, useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '@/components/confirmDialog/ConfirmDialog';

import { ActionsCellWrapper } from './ActionsCellWrapper';

export type ActionsCellProps = {
  onDelete?: () => Promise<unknown>;
  children?: ReactNode;
};

export const ActionsCell = memo(function ActionsCell({
  children,
  onDelete,
}: ActionsCellProps) {
  const { t } = useTranslation(['glossary']);
  const [opened, setOpened] = useState(false);

  return (
    <>
      <ConfirmDialog
        open={opened}
        onConfirm={onDelete}
        onOpenChange={(open: boolean) => setOpened(open)}
      />

      <ActionsCellWrapper>
        {children}
        <Tooltip title={t('glossary:actions.delete')}>
          <Button
            size='icon-xs'
            variant='ghost'
            className='text-danger'
            outline
            onClick={() => setOpened(true)}
          >
            <Trash2 className='size-4' />
          </Button>
        </Tooltip>
      </ActionsCellWrapper>
    </>
  );
});
