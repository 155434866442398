import type { FAQType } from '@kanbu/schema';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useFAQsCols } from '@/features/faqs/useFAQsCols';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/faqs/')({
  component: FaqsPage,
});

export function FaqsPage() {
  const { t } = useTranslation(['globals', 'faqs']);
  const columns = useFAQsCols();
  const handleQuery = useTableQuery(trpcClient.faqs.findAll.query, {
    populate: ['parent'],
  });

  const handleRow = useDetailRowHandler<FAQType>(row => ({
    to: '/faqs/$faqId/edit',
    params: {
      faqId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t('faqs:texts.manage')}
      breadcrumbs={[{ label: t('globals:routeNames.faqs'), to: '/faqs' }]}
      createButton={{
        to: '/faqs/create',
        label: t('faqs:actions.create'),
      }}
      columns={columns}
      queryKey={getQueryKey(trpc.faqs.findAll)}
      onQueryFn={handleQuery}
      onRow={handleRow}
    />
  );
}
