import {
  forgottenPasswordSchema,
  type ForgottenPasswordDTO,
} from '@kanbu/schema/contracts';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { Button, toast } from '@utima/ui';
import { Input, type TypedFormState } from '@utima/ui-informed';
import { useTranslation } from 'react-i18next';

import { BasicForm } from '@/components/form/BasicForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_authLayout/forgotten-password/')({
  component: ForgottenPasswordPage,
});

const initialValues: ForgottenPasswordDTO = {
  email: '',
};

export function ForgottenPasswordPage() {
  const { t } = useTranslation(['glossary', 'auth']);
  const navigate = useNavigate();
  const forgottenPasswordMutation = trpc.auth.forgottenPassword.useMutation();

  const onSubmit = (formState: TypedFormState<ForgottenPasswordDTO>) => {
    forgottenPasswordMutation.mutate(formState.values, {
      onError: (error: unknown) => {
        console.error(error);
        toast.error(t('auth:toasts.forgottenPassword.error.title'), {
          description: t('auth:toasts.forgottenPassword.error.description'),
        });
      },
      onSuccess: () => {
        navigate({
          to: '/forgotten-password/sent',
          search: { email: formState.values.email },
        });
      },
    });
  };

  return (
    <BasicForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      zodSchema={forgottenPasswordSchema}
      className='flex w-full flex-col gap-6'
    >
      <h1 className='text-center text-2xl font-bold'>
        {t('auth:texts.forgottenPassword.title')}
      </h1>

      <div className='text-center text-sm'>
        {t('auth:texts.forgottenPassword.subtitle')}
      </div>

      <Input
        name='email'
        type='email'
        label={t('glossary:labels.email')}
        placeholder={t('glossary:labels.email')}
        required
      />

      <div className='flex justify-between gap-4'>
        <Button variant='muted' outline asChild>
          <Link to='/login'>{t('glossary:actions.backToLogin')}</Link>
        </Button>
        <Button variant='primary' type='submit'>
          {t('glossary:actions.send')}
        </Button>
      </div>
    </BasicForm>
  );
}
