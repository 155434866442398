import { createContext, useContext } from 'react';

import { defaultFormSchemaAdapter, type FormSchemaAdapter } from './formUtils';

export const defaultAdapterContext: FormSchemaContextType = {
  adapter: defaultFormSchemaAdapter,
};

export type FormSchemaContextType = {
  adapter: FormSchemaAdapter;
};

export const FormSchemaContext = createContext<FormSchemaContextType>(
  undefined!,
);

/**
 * Saves initial values and other useful information to context, which can be
 * accessed by components inside the form.
 */
export function useFormSchemaContext() {
  const context = useContext(FormSchemaContext);

  if (!context) {
    throw new Error(
      'No FormSchemaContext found when calling useFormSchemaContext.',
    );
  }

  return context;
}
