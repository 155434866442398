import type { EvalTestResult } from '@kanbu/schema/contracts';
import { DateFormat, formatUtils, renderMarkdown } from '@kanbu/shared';
import { Badge, cn, Separator } from '@utima/ui';
import {
  ChevronDown,
  ChevronUp,
  Clock,
  MessageCircle,
  MessageCircleXIcon,
  Target,
  TestTube2,
} from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface EvaluationAccordionProps {
  result: EvalTestResult;
}

// Generate a color based on the accuracy percentage
export function getAccuracyColor(accuracyPercentage: number) {
  const hue = (accuracyPercentage * 120).toString();

  return `hsl(${hue}, 85%, 75%)`;
}

export function EvaluationAccordion({ result }: EvaluationAccordionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['glossary']);

  const sumAccuracy =
    result.messages.reduce(
      (acc: number, message: EvalTestResult['messages'][number]) =>
        acc + message.results.accuracyPercentage,
      0,
    ) /
    result.messages.length /
    100;

  const incorrectCount = result.messages.filter(
    message => !message.results.isCorrect,
  ).length;

  const totalLatency = result.messages.reduce(
    (acc: number, message: EvalTestResult['messages'][number]) =>
      acc + message.latency,
    0,
  );

  return (
    <div className='mb-2 rounded-md border'>
      <button
        className='flex w-full items-center justify-between p-2 text-left transition-all hover:bg-slate-100'
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className='flex items-center gap-2'>
          <Badge
            style={{
              color: 'black',
              backgroundColor: getAccuracyColor(sumAccuracy),
            }}
            size='xs'
          >
            {formatUtils.percentage(sumAccuracy)}
          </Badge>
          <Badge variant='info' size='xs' outline>
            <MessageCircle className='size-3' /> {result.messages.length}
          </Badge>
          <Badge variant='info' size='xs' outline className='text-slate-800'>
            <Clock className='size-3' />{' '}
            {formatUtils.date(new Date(totalLatency), DateFormat.PreciseTime)} s
          </Badge>
          {incorrectCount > 0 && (
            <Badge variant='danger' size='xs' outline>
              <MessageCircleXIcon className='size-3' /> {incorrectCount}
            </Badge>
          )}
          <span className='overflow-hidden truncate text-sm font-semibold'>
            {formatUtils.truncate(result.messages[0].question, 50)}
          </span>
        </span>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>

      {isOpen && (
        <div className='space-y-4 p-4'>
          {result.messages.map((message, index) => (
            <div
              key={index}
              className='relative rounded-md border bg-white p-4 shadow-sm'
            >
              <span className='absolute right-2 top-2'>
                <Badge
                  variant='info'
                  size='sm'
                  className='text-slate-800'
                  outline
                >
                  <Clock className='size-3' />
                  {formatUtils.date(
                    new Date(message.latency),
                    DateFormat.PreciseTime,
                  )}{' '}
                  s
                </Badge>
              </span>
              <div className='mb-2'>
                <span className='font-semibold'>
                  {t('glossary:labels.question')}:
                </span>
                <p className='mt-1 text-gray-600'>{message.question}</p>
              </div>
              <div className='mb-2'>
                <span className='font-semibold'>
                  {t('glossary:labels.answer')}:
                </span>
                <p
                  className='markdown-wrapper mt-1 text-gray-600'
                  dangerouslySetInnerHTML={{
                    __html: renderMarkdown(message.answer),
                  }}
                />
              </div>
              <div className='mb-2'>
                <span className='font-semibold'>
                  {t('glossary:labels.expectedAnswer')}:
                </span>
                <p className='mt-1 text-gray-600'>{message.expectedOutput}</p>
              </div>
              <Separator className='my-3' />
              <div>
                <ul className='mt-2 space-y-2'>
                  <li className='flex items-center gap-2'>
                    <span
                      className={cn(
                        'flex items-center gap-2 rounded-md bg-slate-100 px-2 py-1 text-xs',
                        message.results.isCorrect
                          ? 'bg-green-300'
                          : 'bg-red-300',
                      )}
                    >
                      <TestTube2 className='size-4' />
                      <span className='font-bold'>
                        {message.results.isCorrect
                          ? t('glossary:labels.correct')
                          : t('glossary:labels.incorrect')}
                      </span>
                    </span>
                    <span
                      className='flex items-center gap-2 rounded-md bg-slate-100 px-2 py-1 text-xs'
                      style={{
                        backgroundColor: getAccuracyColor(
                          message.results.accuracyPercentage / 100,
                        ),
                      }}
                    >
                      <Target className='size-4' />
                      <span>{t('glossary:labels.accuracy')}:</span>
                      <span className='font-bold'>
                        {formatUtils.percentage(
                          message.results.accuracyPercentage,
                        )}
                      </span>
                    </span>
                  </li>
                  <li>
                    <p
                      className={cn(
                        'mt-1 text-sm',
                        message.results.isCorrect && 'text-green-700',
                        !message.results.isCorrect && 'text-red-800',
                      )}
                    >
                      {message.results.explanation}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
