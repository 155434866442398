import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { Button, toast } from '@utima/ui';
import { Input, type TypedFormState } from '@utima/ui-informed';
import { jwtDecode, type JwtPayload } from 'jwt-decode';
import { useTranslation } from 'react-i18next';

import { PasswordFields } from '@/components/fields/passwordFields/PasswordFields';
import { BasicForm } from '@/components/form/BasicForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_authLayout/reset-password/')({
  component: ResetPasswordPage,
  validateSearch: (search: Record<string, unknown>): { token: string } => {
    return {
      token: (search.token as string) || '',
    };
  },
});

export function ResetPasswordPage() {
  const { t } = useTranslation(['glossary', 'auth']);
  const navigate = useNavigate();
  const { token } = Route.useSearch();
  const changePasswordMutation = trpc.auth.changePassword.useMutation();

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  let exp: number = 0;

  try {
    const jwtPayload = jwtDecode<JwtPayload & { email: string }>(token);
    initialValues.email = jwtPayload.email;
    exp = jwtPayload.exp!;
  } catch (e) {
    console.error(e);
  }

  const onSubmit = (formState: TypedFormState<typeof initialValues>) => {
    changePasswordMutation.mutate(
      {
        token: token,
        email: formState.values.email,
        password: formState.values.password,
      },
      {
        onError: (error: unknown) => {
          console.error(error);
          toast.error(t('auth:toasts.resetPassword.error.title'), {
            description: t('auth:toasts.resetPassword.error.description'),
          });
        },
        onSuccess: () => {
          toast.success(t('auth:toasts.resetPassword.success.title'), {
            description: t('auth:toasts.resetPassword.success.description'),
          });

          navigate({ to: '/login' });
        },
      },
    );
  };

  // Token is either expired or invalid
  if (!token || !initialValues.email || exp < Date.now() / 1000) {
    return (
      <div className='flex flex-col items-center gap-6'>
        <h1 className='text-center text-2xl font-bold'>
          {t('auth:texts.resetPassword.title')}
        </h1>
        <div className='text-center text-sm'>
          {t('auth:texts.resetPassword.invalidToken')}
        </div>
        <div className='flex justify-center'>
          <Button variant='ghost' outline type='submit' asChild>
            <Link to='/login' className='text-sm'>
              {t('glossary:actions.backToLogin')}
            </Link>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <BasicForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      className='flex w-full flex-col gap-6'
    >
      <h1 className='text-center text-2xl font-bold'>
        {t('auth:texts.resetPassword.title')}
      </h1>

      <Input
        name='email'
        type='email'
        label={t('glossary:labels.email')}
        disabled
        required
      />

      <PasswordFields
        title={t('auth:texts.verification.passwordFields')}
        hasOldPassword={false}
      />

      <div className='flex justify-between gap-4'>
        <Button variant='muted' outline asChild>
          <Link to='/login'>{t('glossary:actions.backToLogin')}</Link>
        </Button>
        <Button variant='primary' type='submit'>
          {t('glossary:actions.resetPassword')}
        </Button>
      </div>
    </BasicForm>
  );
}
