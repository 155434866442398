import { Suspense, lazy } from 'react';

import { AppSettings } from '@/constants/AppSettings';

export const Devtools = lazy(() =>
  import('@utima/ui-informed/devtools').then(res => {
    return {
      default: res.Devtools,
    };
  }),
);

export function UIFormDevtools() {
  return AppSettings.debug ? (
    <Suspense fallback={null}>
      <Devtools className='fixed bottom-12 left-2 z-40' />
    </Suspense>
  ) : null;
}
