import { useMemo } from 'react';

import { createFormSchema, type FormSchemaDef } from './formUtils';

export function useFieldsSchema(
  fieldsDef: FormSchemaDef['$fields'],
  dependencies: any[] = [],
) {
  const schema = useMemo(
    () =>
      createFormSchema({
        $fields: fieldsDef,
      }),
    dependencies,
  );

  return { schema };
}
