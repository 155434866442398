import type { ChatType } from '@kanbu/schema';
import { Button, Tooltip } from '@utima/ui';
import { Copy, CopyCheckIcon } from 'lucide-react';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';

export interface ChatbotScriptFieldsProps {
  data?: ChatType;
}

export const ChatbotScriptFields = memo(function ChatbotScriptFields({
  data,
}: ChatbotScriptFieldsProps) {
  const { t } = useTranslation(['glossary', 'enums']);
  const [isCopied, setIsCopied] = useState(false);

  const script = `<script>
  var script = document.createElement('script');
  script.src = 'https://loader.kanbu.ai/v0/chatbot.js';
  script.type = 'module';
  script.async = true;
  script.addEventListener('load', function () {
    var chatbotElement = document.createElement('kanbu-chatbot');
    chatbotElement.setAttribute('chat', '${data?.id}');
    document.body.append(chatbotElement);
  });

  document.body.append(script);
</script>`;

  const copyToClipboard = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      navigator.clipboard.writeText(script).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
    },
    [script],
  );

  if (!data) {
    return null;
  }

  return (
    <Card
      title={
        <span className='flex items-center gap-2'>
          <span>{t('glossary:labels.script')}</span>
          <Tooltip title={t('glossary:actions.copy')} delayDuration={0}>
            <Button onClick={copyToClipboard} variant='primary' size='icon-xs'>
              {isCopied ? (
                <CopyCheckIcon className='size-4' />
              ) : (
                <Copy className='size-4' />
              )}
            </Button>
          </Tooltip>
        </span>
      }
    >
      <div className='relative'>
        <pre className='mb-4 overflow-x-auto font-mono text-xs'>{script}</pre>
      </div>
    </Card>
  );
});
