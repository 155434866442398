import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { OperatorForm } from '@/features/operators/OperatorForm';

export const Route = createFileRoute('/_baseLayout/operators/create')({
  component: OperatorsCreatePage,
});

export function OperatorsCreatePage() {
  const { t } = useTranslation(['globals', 'operators']);

  return (
    <FormPage
      title={t('operators:texts.create')}
      breadcrumbs={[
        { label: t('globals:routeNames.operators'), to: '/operators' },
        { label: t('operators:texts.create'), to: '/operators/create' },
      ]}
    >
      <OperatorForm />
    </FormPage>
  );
}
