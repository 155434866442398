import { cn } from '@utima/ui';
import { Loader2 } from 'lucide-react';

export type LoaderProps = {
  visible?: boolean;
  className?: string;
};

export function Loader({ visible = true, className }: LoaderProps) {
  if (!visible) {
    return null;
  }

  return (
    <div
      className={cn(
        'absolute flex size-full items-center justify-center bg-white/75 text-primary z-50',
        className,
      )}
    >
      <Loader2 className='size-10 animate-spin' />
    </div>
  );
}
