import type { OrganizationType } from '@kanbu/schema';
import {
  createOrganizationSchema,
  updateOrganizationSchema,
} from '@kanbu/schema/contracts';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

import { useOrganizationMutation } from './useOrganizationMutation';

export type OrganizationFormProps = {
  data?: OrganizationType;
};

export const OrganizationForm = memo(function OrganizationForm({
  data,
}: OrganizationFormProps) {
  const { t } = useTranslation(['glossary']);
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useOrganizationMutation();

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'name',
        label: t('glossary:labels.name'),
        required: true,
      },
      {
        name: 'monthlyUsageLimit',
        label: t('glossary:labels.monthlyUsageLimit'),
        type: 'number',
        uiProps: {
          addonAfter: '$',
        },
      },
    ],
    [t],
  );

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createOrganizationSchema}
      updateSchema={updateOrganizationSchema}
      onCreate={create}
      onUpdate={update}
      onRemove={remove}
      basePath='/organizations'
      idParamName='organizationId'
    >
      <EntityCRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col />
      <EntityCRUDForm.Col>
        <MetaFields data={data} />
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
