import type { ChatType } from '@kanbu/schema';
import { Role } from '@kanbu/schema/enums';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { Link } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Tooltip } from '@utima/ui';
import { FileScan, BugPlayIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';
import { AppSettings } from '@/constants/AppSettings';
import { useAccess } from '@/hooks/useAccess';

import { useChatMutation } from './useChatMutation';

const columnHelper = createColumnHelper<ChatType>();

export function useChatsCols() {
  const { t } = useTranslation(['glossary', 'chats']);
  const { hasAccess } = useAccess();
  const {
    remove: { mutateAsync: remove },
  } = useChatMutation();

  return useMemo(
    () => [
      columnHelper.accessor(row => row.id, {
        id: 'id',
        header: t('glossary:labels.id'),
        meta: {
          sortable: 'id',
        },
      }),
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: t('glossary:labels.name'),
        meta: {
          sortable: 'name',
        },
      }),
      ...(hasAccess([Role.SuperAdmin])
        ? [
            columnHelper.accessor(row => row.agent, {
              id: 'agent',
              header: t('glossary:labels.agent'),
              cell: row => (
                <span className='whitespace-nowrap font-mono'>
                  {row.getValue()}
                </span>
              ),
              meta: {
                sortable: 'agent',
              },
            }),
            columnHelper.accessor(row => row.model, {
              id: 'model',
              header: t('glossary:labels.model'),
              cell: row => (
                <span className='whitespace-nowrap font-mono'>
                  {row.getValue()}
                </span>
              ),
              meta: {
                sortable: 'model',
              },
            }),
            columnHelper.accessor(row => row.embeddingsVersion, {
              id: 'embeddingsVersion',
              header: t('glossary:labels.embeddingsVersion'),
              cell: row => (
                <span className='whitespace-nowrap font-mono'>
                  {row.getValue()}
                </span>
              ),
              meta: {
                sortable: 'embeddingsVersion',
              },
            }),
          ]
        : []),
      columnHelper.accessor(row => row.updatedAt, {
        id: 'updatedAt',
        header: t('glossary:labels.updatedAt'),
        cell: row => formatUtils.date(row.getValue(), DateFormat.DateTime),
        meta: {
          sortable: 'updatedAt',
        },
      }),
      columnHelper.accessor(row => row.documentsCount, {
        id: 'documentsCount',
        header: t('glossary:labels.documentsCount'),
        meta: {
          sortable: 'documentsCount',
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: t('glossary:labels.actions'),
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })}>
            <Tooltip title={t('chats:texts.playgroundLink')}>
              <Button
                variant='ghost'
                size='icon-xs'
                className='text-emerald-500'
                outline
                asChild
              >
                <a
                  target='_blank'
                  href={`${AppSettings.playgroundURL}/?chatId=${row.original.id}`}
                  rel='noreferrer'
                >
                  <BugPlayIcon className='size-4' />
                </a>
              </Button>
            </Tooltip>
            <Tooltip title={t('chats:texts.documents')}>
              <Button variant='ghost' size='icon-xs' outline asChild>
                <Link to={`/chats/${row.original.id}/documents`}>
                  <FileScan className='size-4' />
                </Link>
              </Button>
            </Tooltip>
          </ActionsCell>
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [hasAccess, remove, t],
  );
}
