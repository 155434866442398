import type { ReactNode } from 'react';

import {
  BreadCrumb,
  type BreadCrumbItem,
} from '@/components/breadCrumb/BreadCrumb';

export interface ResourcePageProps {
  title?: string;
  breadcrumbs: BreadCrumbItem[];
  actions?: ReactNode;
  children: ReactNode;
}

/**
 * Tempalte for basic resource/entity page with breadcrumbs.
 */
export function ResourcePage({
  title,
  breadcrumbs,
  actions,
  children,
}: ResourcePageProps) {
  return (
    <>
      <BreadCrumb items={breadcrumbs} title={title}>
        {actions}
      </BreadCrumb>
      {children}
    </>
  );
}
