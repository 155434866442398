import { Link, type Register } from '@tanstack/react-router';
import { Button, cn } from '@utima/ui';
import { PlusCircle } from 'lucide-react';
import { memo, type ComponentPropsWithoutRef } from 'react';
import type { LiteralUnion } from 'type-fest';

export interface AddNewButtonProps
  extends ComponentPropsWithoutRef<typeof Button> {
  to: LiteralUnion<keyof Register['router']['routesByPath'], string>;
  params?: any;
}

/**
 * Button for adding new entities in tables. It should be the same
 * for most listings.
 */
export const AddNewButton = memo(function AddNewButton({
  to,
  params,
  children,
  className,
  disabled,
  ...restProps
}: AddNewButtonProps) {
  return (
    <Button
      asChild
      variant='primary'
      disabled={disabled}
      className={cn(disabled && 'opacity-50 cursor-not-allowed', className)}
      {...restProps}
    >
      <Link to={to} params={params}>
        <PlusCircle className='size-4' /> {children}
      </Link>
    </Button>
  );
});
