import { z } from 'zod';

export const evaluationResultSchema = z.object({
  isCorrect: z.boolean().describe('Whether the answer is correct.'),
  accuracyPercentage: z
    .number()
    .describe('The accuracy percentage of the answer.'),
  explanation: z.string().describe('A brief explanation of the evaluation.'),
});

export type EvaluationResult = z.infer<typeof evaluationResultSchema>;

export type EvalTestResult = {
  messages: {
    answer: string;
    question: string;
    expectedOutput: string;
    results: EvaluationResult;
    latency: number;
  }[];
};

export type EvalTestResults = EvalTestResult[];

export const evaluationTestCaseSchema = z.object({
  messages: z.array(
    z.object({
      question: z.string().describe('The question to be answered.'),
      expectedOutput: z.string().describe('The expected output.'),
    }),
  ),
});

export type EvaluationTestCase = z.infer<typeof evaluationTestCaseSchema>;

export const evaluationSchema = z
  .object({
    chatId: z.string().uuid(),
    testCases: evaluationTestCaseSchema.array(),
  })
  .strict();

export type Evaluation = z.infer<typeof evaluationSchema>;

export const evaluationGenerateSchema = z.object({
  sourceDocumentId: z.string().uuid(),
  count: z.number().optional(),
});

export type EvaluationGenerate = z.infer<typeof evaluationGenerateSchema>;
