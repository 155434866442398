import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { FAQForm } from '@/features/faqs/FAQForm';

export const Route = createFileRoute('/_baseLayout/faqs/create')({
  component: FAQsCreatePage,
});

export function FAQsCreatePage() {
  const { t } = useTranslation(['globals', 'faqs']);

  return (
    <FormPage
      title={t('faqs:texts.create')}
      breadcrumbs={[
        { label: t('globals:routeNames.faqs'), to: '/faqs' },
        { label: t('faqs:texts.create'), to: '/faqs/create' },
      ]}
    >
      <FAQForm />
    </FormPage>
  );
}
