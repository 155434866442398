import { formatUtils, type FindOneParams } from '@kanbu/shared';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { OperatorForm } from '@/features/operators/OperatorForm';
import { trpc } from '@/services/trpc';

const requestParams: Omit<FindOneParams, 'id'> = {
  populate: ['chat'],
  fields: ['*', 'chat.name', 'chat.id'],
};

export const Route = createFileRoute('/_baseLayout/operators/$operatorId/edit')(
  {
    component: OperatorsEditPage,
    loader: async ({ context: { trpcUtils }, params: { operatorId } }) =>
      trpcUtils.operators.findOne.ensureData({
        id: operatorId,
        ...requestParams,
      }),
  },
);

export function OperatorsEditPage() {
  const { operatorId } = Route.useParams();
  const { t } = useTranslation(['glossary', 'operators']);
  const [data] = trpc.operators.findOne.useSuspenseQuery({
    id: operatorId,
    ...requestParams,
  });

  return (
    <FormPage
      title={`${t('operators:texts.edit')} - ${formatUtils.fullName(data)}`}
      breadcrumbs={[
        { label: t('operators:texts.manage'), to: '/operators' },
        {
          label: formatUtils.fullName(data),
          to: '/operators/$operatorId/edit',
          params: { operatorId },
        },
      ]}
    >
      <OperatorForm data={data} />
    </FormPage>
  );
}
