import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useSourceDocumentPartsCols } from '@/features/sourceDocuments/useSourceDocumentPartsCols';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute(
  '/_baseLayout/chats/$chatId/documents/$sourceDocumentId/parts/',
)({
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin, Role.TmpAdmin]),
  component: SourceDocumentPartsPage,
});

export function SourceDocumentPartsPage() {
  const { chatId, sourceDocumentId } = Route.useParams();
  const { t } = useTranslation(['globals', 'sourceDocumentParts', 'chats']);
  const columns = useSourceDocumentPartsCols();

  const handleQuery = useTableQuery(
    trpcClient.sourceDocumentParts.findAll.query,
    {
      searchable: false,
      where: {
        parent: sourceDocumentId,
      },
    },
  );

  return (
    <TablePage
      title={t('sourceDocumentParts:texts.manage')}
      breadcrumbs={[
        {
          type: 'more',
        },
        {
          label: t('globals:routeNames.sourceDocuments'),
          to: '/chats/$chatId/documents',
          params: { chatId },
        },
        {
          label: t('globals:routeNames.sourceDocumentParts'),
          to: '/chats/$chatId/documents/$sourceDocumentId/parts',
          params: { chatId },
        },
      ]}
      columns={columns}
      queryKey={[
        ...getQueryKey(trpc.sourceDocumentParts.findAll),
        { sourceDocumentId, chatId },
      ]}
      onQueryFn={handleQuery}
    />
  );
}
