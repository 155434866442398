import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ForbiddenError } from '@/lib/errors';

import { Error } from './Error';

export type ErrorPageProps = {
  error: unknown;
};

export const ErrorPage = memo(function ErrorPage({ error }: ErrorPageProps) {
  const { t } = useTranslation(['errors']);

  if (error instanceof ForbiddenError) {
    return (
      <Error
        statusCode={403}
        title={t('errors:httpStatus.403.title')}
        description={t('errors:httpStatus.403.message')}
      />
    );
  }

  return (
    <Error
      statusCode={500}
      title={t('errors:httpStatus.500.title')}
      description={t('errors:httpStatus.500.message')}
    />
  );
});
