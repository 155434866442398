import { trpc } from '@/services/trpc';

export function useThreadMutation() {
  const utils = trpc.useUtils();

  const create = trpc.threads.create.useMutation({
    onSuccess: async () => utils.threads.findAll.invalidate(),
  });

  const update = trpc.threads.update.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.threads.findAll.invalidate(),
        utils.threads.findOne.invalidate({ id }),
      ]),
  });

  const remove = trpc.threads.delete.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.threads.findAll.invalidate(),
        utils.chatUsers.findAll.invalidate(),
      ]),
  });

  return {
    create,
    update,
    remove,
  };
}
