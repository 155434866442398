import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { FormPage } from '@/components/page/FormPage';
import { UserForm } from '@/features/users/components/UserForm';

export const Route = createFileRoute('/_baseLayout/users/create')({
  component: UsersCreatePage,
});

export function UsersCreatePage() {
  const { t } = useTranslation(['glossary', 'users']);

  return (
    <FormPage
      title={t('users:texts.create')}
      breadcrumbs={[
        { label: t('users:texts.manage'), to: '/users' },
        { label: t('users:texts.create'), to: '/users/create' },
      ]}
    >
      <UserForm />
    </FormPage>
  );
}
