import { Navigate, useNavigate } from '@tanstack/react-router';
import type { TypedFormState } from '@utima/ui-informed';
import { useCallback, useState } from 'react';
import type { z } from 'zod';

import { CRUDForm } from './CRUDForm';

interface EntityCRUDFormProps<
  Data extends { id: string },
  CreateSchema extends z.ZodType,
  UpdateSchema extends z.ZodType,
> {
  initialData?: Data;
  createSchema: CreateSchema;
  updateSchema: UpdateSchema;
  onCreate?: (values: z.infer<CreateSchema>) => Promise<Data>;
  onUpdate: (values: z.infer<UpdateSchema>) => Promise<Data>;
  onRemove?: (data: { id: string }) => Promise<void>;
  // FIXME This is temporary hotfix to transform referenced values before submitting
  transformValues?: (
    values: z.infer<UpdateSchema> | z.infer<CreateSchema>,
    action: 'update' | 'create',
  ) => z.infer<UpdateSchema> | z.infer<CreateSchema>;
  basePath?: string;
  idParamName?: string;
  children: React.ReactNode;
}

/**
 * Wrapper for CRUDForm that handles creation and update of entities.
 */
export function EntityCRUDForm<
  Data extends { id: string },
  CreateSchema extends z.ZodType,
  UpdateSchema extends z.ZodType,
  FormState = Data,
>({
  initialData,
  createSchema,
  updateSchema,
  onCreate,
  onUpdate,
  onRemove,
  basePath,
  idParamName,
  children,
  transformValues,
}: EntityCRUDFormProps<Data, CreateSchema, UpdateSchema>) {
  const navigate = useNavigate();
  const [isRemoved, setIsRemoved] = useState(false);

  const handleDelete = useCallback(async () => {
    if (initialData && onRemove) {
      await onRemove({ id: initialData.id });
      setIsRemoved(true);
    }
  }, [initialData, onRemove]);

  const handleSubmit = useCallback(
    async (formState: TypedFormState<FormState | undefined>) => {
      const { values } = formState;

      if (!values) {
        return;
      }

      if (initialData) {
        let requestData = {
          ...values,
          id: initialData.id,
        };

        if (transformValues) {
          requestData = transformValues(requestData, 'update');
        }

        await onUpdate(updateSchema.parse(requestData));
      } else if (onCreate) {
        const newData = await onCreate(
          createSchema.parse(
            transformValues ? transformValues(values, 'create') : values,
          ),
        );

        if (newData && basePath) {
          navigate({
            to: `${basePath}/${newData.id}/edit`,
            params: idParamName ? { [idParamName]: newData.id } : undefined,
          });
        }
      }
    },
    [
      initialData,
      onCreate,
      onUpdate,
      updateSchema,
      transformValues,
      createSchema,
      navigate,
      basePath,
      idParamName,
    ],
  );

  // Redirect on removal
  if (isRemoved && basePath) {
    return <Navigate to={basePath} />;
  }

  return (
    <CRUDForm
      initialValues={initialData as never}
      zodSchema={
        (initialData
          ? updateSchema
          : createSchema) as unknown as z.ZodObject<z.ZodRawShape>
      }
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    >
      {children}
    </CRUDForm>
  );
}

EntityCRUDForm.Col = CRUDForm.Col;
