import { trpc } from '@/services/trpc';

export function useOperatorMutation() {
  const utils = trpc.useUtils();

  const create = trpc.operators.create.useMutation({
    onSuccess: async () => utils.operators.findAll.invalidate(),
  });

  const update = trpc.operators.update.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.operators.findAll.invalidate(),
        utils.operators.findOne.invalidate({ id }),
      ]),
  });

  const remove = trpc.operators.delete.useMutation({
    onSuccess: async (data, { id }) => utils.operators.findAll.invalidate(),
  });

  return {
    create,
    update,
    remove,
  };
}
