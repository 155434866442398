import type { ChatUserType } from '@kanbu/schema';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';

import { useChatUserMutation } from './useChatUserMutation';

const columnHelper = createColumnHelper<ChatUserType>();

export function useChatUsersCols() {
  const { t } = useTranslation(['glossary', 'chatUsers']);
  const {
    remove: { mutateAsync: remove },
  } = useChatUserMutation();

  return useMemo(
    () => [
      columnHelper.accessor(row => row.id, {
        id: 'id',
        header: t('glossary:labels.id'),
        meta: {
          sortable: 'id',
        },
      }),
      columnHelper.accessor(row => row.threadsCount, {
        id: 'threadsCount',
        header: t('glossary:labels.threadsCount'),
        meta: {
          sortable: 'threadsCount',
        },
      }),
      columnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: t('glossary:labels.createdAt'),
        cell: row => formatUtils.date(row.getValue(), DateFormat.DateTime),
        meta: {
          sortable: 'createdAt',
        },
      }),
      columnHelper.accessor(row => row.updatedAt, {
        id: 'updatedAt',
        header: t('glossary:labels.updatedAt'),
        cell: row => formatUtils.date(row.getValue(), DateFormat.DateTime),
        meta: {
          sortable: 'updatedAt',
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: t('glossary:labels.actions'),
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })} />
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [t, remove],
  );
}
