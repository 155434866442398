import type { AgentConfig } from '@kanbu/schema';
import {
  ChatModel,
  Language,
  ResponseLengthPreset,
  Role,
  TonePreset,
} from '@kanbu/schema/enums';
import type { TypedFormState } from '@utima/ui-informed';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useAccess } from '@/hooks/useAccess';

export type AgentConfigFieldsProps = {};

export const AgentConfigFields = memo(
  function AgentConfigFields({}: AgentConfigFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums']);
    const { hasAccess } = useAccess();
    const { schema } = useFieldsSchema(
      [
        {
          name: 'agentConfig.companyName',
          label: t('glossary:labels.companyName'),
          defaultValue: 'Kanbu',
          required: true,
        },
        {
          name: 'agentConfig.companyDescription',
          label: t('glossary:labels.companyDescription'),
          type: 'text',
          uiProps: {
            rows: 5,
          },
          uiControl: 'textarea',
          required: true,
          defaultValue:
            'Kanbu is a company that provides AI-powered chatbots for customer support and knowledge management.',
        },
        {
          name: 'agentConfig.tonePreset',
          label: t('glossary:labels.tonePreset'),
          type: 'select',
          options: optionsFromEnum(TonePreset, key =>
            t(`enums:tonePreset.${key}`),
          ),
          defaultValue: TonePreset.Friendly,
        },
        {
          name: 'agentConfig.responseLengthPreset',
          label: t('glossary:labels.responseLengthPreset'),
          type: 'select',
          options: optionsFromEnum(ResponseLengthPreset, key =>
            t(`enums:responseLengthPreset.${key}`),
          ),
          defaultValue: ResponseLengthPreset.Balanced,
        },
        {
          name: 'agentConfig.showSources',
          label: t('glossary:labels.showSources'),
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: true,
        },
        {
          name: 'agentConfig.capabilities',
          visible: hasAccess([Role.SuperAdmin]),
          label: t('glossary:labels.capabilities'),
          type: 'text',
          uiProps: {
            rows: 5,
          },
          uiControl: 'textarea',
          required: true,
          defaultValue:
            "Answer questions about the company, it's products and services",
        },
        {
          name: 'agentConfig.tone',
          visible: hasAccess([Role.SuperAdmin]),
          label: `${t('glossary:labels.tone')} (DEPRECATED)`,
          type: 'text',
          uiControl: 'textarea',
          defaultValue: 'Professional, kind, friendly, helpful',
        },
        {
          name: 'agentConfig.systemPrompt',
          visible: hasAccess([Role.SuperAdmin]),
          label: t('glossary:labels.systemPrompt'),
          type: 'text',
          uiProps: {
            rows: 10,
          },
          uiControl: 'textarea',
          defaultValue: 'You are an AI assistant.',
        },
        {
          name: 'agentConfig.imageSupport',
          visible: hasAccess([Role.SuperAdmin]),
          label: t('glossary:labels.imageSupport'),
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: false,
        },
        {
          name: 'agentConfig.language',
          visible: hasAccess([Role.SuperAdmin]),
          label: `${t('glossary:labels.language')} (DEPRECATED)`,
          required: true,
          defaultValue: Language.Czech,
          type: 'select',
          options: optionsFromEnum(Language, key => t(`enums:language.${key}`)),
        },
        {
          name: 'agentConfig.smallModel',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'smallModel',
          type: 'select',
          options: optionsFromEnum(ChatModel, key =>
            t(`enums:chatModel.${key}`),
          ),
          required: true,
          defaultValue: ChatModel.Gpt4oMini,
        },
        {
          name: 'agentConfig.hybridAlpha',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'hybridAlpha',
          type: 'number',
          defaultValue: 0.4,
        },
        {
          name: 'agentConfig.rewriteRetries',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'rewriteRetries',
          type: 'number',
          defaultValue: 1,
        },
        {
          name: 'agentConfig.historyLimit',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'historyLimit',
          type: 'number',
          defaultValue: 8,
        },
        {
          name: 'agentConfig.contextHistoryLimit',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'contextHistoryLimit',
          type: 'number',
          defaultValue: 8,
        },
        {
          name: 'agentConfig.retrievalCount',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'retrievalCount',
          type: 'number',
          defaultValue: 50,
        },
        {
          name: 'agentConfig.rerank',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'rerank',
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: true,
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              !!(formState as TypedFormState<{ agentConfig: AgentConfig }>)
                .values?.agentConfig?.rerank,
            $fields: [
              {
                name: 'agentConfig.rerankCount',
                visible: hasAccess([Role.SuperAdmin]),
                label: 'rerankCount',
                type: 'number',
                defaultValue: 20,
              },
              {
                name: 'agentConfig.rerankWeight',
                visible: hasAccess([Role.SuperAdmin]),
                label: 'rerankWeight',
                type: 'number',
                defaultValue: 0.7,
              },
            ],
          },
        },
        {
          name: 'agentConfig.minScore',
          visible: hasAccess([Role.SuperAdmin]),
          label: t('glossary:labels.minScore'),
          type: 'number',
          defaultValue: 0,
        },
      ],
      [t, hasAccess],
    );

    return (
      <Card title={t('glossary:labels.agentConfig')}>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
