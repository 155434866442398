import type { OrganizationType } from '@kanbu/schema';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { createColumnHelper } from '@tanstack/react-table';
import { cn } from '@utima/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';

import { useOrganizationMutation } from './useOrganizationMutation';

const columnHelper = createColumnHelper<OrganizationType>();

export function useOrganizationsCols() {
  const { t } = useTranslation(['glossary', 'organizations']);
  const {
    remove: { mutateAsync: remove },
  } = useOrganizationMutation();

  return useMemo(
    () => [
      columnHelper.accessor(row => row.id, {
        id: 'id',
        header: t('glossary:labels.id'),
      }),
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: t('glossary:labels.name'),
        meta: {
          sortable: 'createdAt',
        },
      }),
      columnHelper.accessor(row => row.createdAt, {
        id: 'createdAt',
        header: t('glossary:labels.createdAt'),
        cell: row => formatUtils.date(row.getValue(), DateFormat.DateTime),
        meta: {
          sortable: 'createdAt',
        },
      }),
      columnHelper.accessor(row => row.monthlyUsageLimit, {
        id: 'monthlyUsage',
        header: t('glossary:labels.monthlyUsage'),
        cell: ({ row }) => {
          const current = row.original.currentMonthlyUsage ?? 0;
          const limit = row.original.monthlyUsageLimit;

          if (!limit) {
            return (
              <span className='font-semibold'>
                {formatUtils.currencyDollar(current)}
              </span>
            );
          }

          return (
            <span
              className={cn(
                'inline-flex gap-1 items-baseline',
                current > limit ? 'text-red-600' : 'text-green-600',
              )}
            >
              <span className='font-semibold'>
                {formatUtils.currencyDollar(current)}
              </span>
              /
              <span className='text-xs font-semibold'>
                {formatUtils.currencyDollar(limit)}
              </span>
            </span>
          );
        },
        meta: {
          align: 'right',
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: t('glossary:labels.actions'),
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })} />
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [t, remove],
  );
}
