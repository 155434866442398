import type { ChatType } from '@kanbu/schema';
import {
  evaluationSchema,
  type EvalTestResults,
  type EvaluationTestCase,
} from '@kanbu/schema/contracts';
import { Button, Dialog, Separator, toast, Resizable } from '@utima/ui';
import { Download, SquareEqual } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { trpc } from '@/services/trpc';

import { EvaluationEditor } from '../evaluationResults/EvaluationEditor';
import { EvaluationResults } from '../evaluationResults/EvaluationResults';
import { useEvaluationXls } from '../evaluationResults/useEvaluationXls';

interface EvaluationFieldsProps {
  data: ChatType | undefined;
}

export function EvaluationFields({ data }: EvaluationFieldsProps) {
  const { t } = useTranslation(['glossary', 'chats']);
  const [editorContent, setEditorContent] = useState('');
  const [totalMessages, setTotalMessages] = useState(0);
  const { downloadXls } = useEvaluationXls();
  const [evaluationResults, setEvaluationResults] = useState<EvalTestResults>(
    [],
  );

  const { isPending, mutate } = trpc.evaluation.evaluate.useMutation({
    onSuccess: async data => {
      for await (const result of data) {
        setEvaluationResults(prev => [...prev, result]);
      }
    },
  });

  const handleEditorChange = useCallback((value: string) => {
    setEditorContent(value);
    try {
      const parsedData = JSON.parse(value) as EvaluationTestCase[];
      setTotalMessages(
        parsedData.reduce((acc, testCase) => acc + testCase.messages.length, 0),
      );
    } catch (error) {
      console.error(error);
      setTotalMessages(0);
    }
  }, []);

  const handleEvaluate = useCallback(() => {
    try {
      if (data?.id) {
        const parsedData = evaluationSchema.parse({
          chatId: data.id,
          testCases: JSON.parse(editorContent),
        });

        mutate(parsedData);
        setEvaluationResults([]);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('chats:toasts.evaluate.error.title'), {
        description: t('chats:toasts.evaluate.error.description'),
      });
    }
  }, [data?.id, mutate, t, editorContent]);

  const handleExport = useCallback(() => {
    if (data) {
      downloadXls(evaluationResults, data.id, data);
    }
  }, [data, downloadXls, evaluationResults]);

  if (!data?.id) {
    return null;
  }

  return (
    <Card title={t('glossary:labels.evaluation')}>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button variant='success'>
            <SquareEqual className='mr-1 size-4' />{' '}
            {t('glossary:actions.evaluate')}
          </Button>
        </Dialog.Trigger>
        <Dialog.Content className='flex size-full max-h-[95%] max-w-[1680px] flex-col'>
          <Dialog.Title className='grow-0'>
            {t('chats:dialogs.evaluate.title')}
          </Dialog.Title>
          <Dialog.Description className='grow-0'>
            {t('chats:dialogs.evaluate.description')}
          </Dialog.Description>
          <Resizable.Group direction='horizontal'>
            <Resizable.Panel>
              <div className='size-full pr-3'>
                <EvaluationEditor onChange={handleEditorChange} />
              </div>
            </Resizable.Panel>
            <Resizable.Handle withHandle />
            <Resizable.Panel>
              <div className='size-full grow overflow-y-auto pl-3'>
                <EvaluationResults
                  chat={data}
                  data={evaluationResults}
                  loading={isPending}
                  totalMessages={totalMessages}
                />
              </div>
            </Resizable.Panel>
          </Resizable.Group>
          <Dialog.Footer>
            <Button
              onClick={handleExport}
              disabled={isPending || evaluationResults.length === 0}
              className='flex items-center gap-2'
            >
              <Download className='size-4' />
              {t('glossary:actions.exportResults')}
            </Button>

            <Separator orientation='vertical' />

            <Button
              type='button'
              variant='success'
              onClick={handleEvaluate}
              loading={isPending}
              disabled={isPending}
            >
              {t('glossary:actions.evaluate')}
            </Button>
            <Dialog.Close asChild>
              <Button variant='ghost' outline>
                {t('glossary:actions.close')}
              </Button>
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </Card>
  );
}
