import type defaultConfig from '../../config/default.cjs';

export type ConfigType = {
  debug: boolean;
};

const envConfig = __KANBU_CONFIG__ as any as (typeof defaultConfig)['app'] & {
  debug: boolean;
  version: string;
};

export const AppSettings = {
  ...envConfig,
  debug: document.cookie.includes('debug=true') || envConfig.debug,
};
