import escapeStringRegexp from 'escape-string-regexp';

export const SPECIAL_CHARS = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
export const MIN_LENGTH = 6;

export enum PasswordValidationError {
  LENGTH = 'length',
  MATCH = 'match',
  NUMBER = 'number',
  SPECIAL = 'special',
  UPPERCASE = 'uppercase',
}

const NUMBER_REGEX = /\d/;
const UPPERCASE_REGEX = /[A-Z]/;
const SPECIAL_REGEX = new RegExp(`[${escapeStringRegexp(SPECIAL_CHARS)}]`);

export function passwordValidate(password: string, confirmPassword: string) {
  /**
   * Password fields are required by the form validation.
   */
  if (!password || !confirmPassword) {
    return;
  }

  const errors: PasswordValidationError[] = [];

  if (password.length < MIN_LENGTH || confirmPassword.length < MIN_LENGTH) {
    errors.push(PasswordValidationError.LENGTH);
  }

  if (password !== confirmPassword) {
    errors.push(PasswordValidationError.MATCH);
  }

  if (!NUMBER_REGEX.test(password) || !NUMBER_REGEX.test(confirmPassword)) {
    errors.push(PasswordValidationError.NUMBER);
  }

  if (
    !UPPERCASE_REGEX.test(password) ||
    !UPPERCASE_REGEX.test(confirmPassword)
  ) {
    errors.push(PasswordValidationError.UPPERCASE);
  }

  if (!SPECIAL_REGEX.test(password) || !SPECIAL_REGEX.test(confirmPassword)) {
    errors.push(PasswordValidationError.SPECIAL);
  }

  return errors;
}
