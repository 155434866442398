import {
  FormControl,
  useFormContext,
  type FormControlProps,
} from '@utima/ui-informed';
import get from 'lodash.get';
import { memo, type ReactNode } from 'react';

import { Combobox, type ComboboxProps } from '@/components/combobox/Combobox';
import { ComboboxItem } from '@/components/combobox/ComboboxItem';

export interface ComboboxControlProps<TData>
  extends Omit<FormControlProps, 'render' | 'type' | 'children'>,
    Omit<ComboboxProps<TData, string>, 'children' | 'value'> {
  renderDisplayValue?: (item: TData | undefined) => ReactNode | string;
  renderSearchValue?: (item: TData) => string;
  children?: ComboboxProps<TData, string>['children'];
}

/**
 * Control wrapper around combobox used for fetching dynamic data
 * in a controlled way and displaying it in a dropdown.
 */
function ComboboxControlBase<TData extends { id: string }>({
  name,
  dataProviderFactory,
  renderDisplayValue,
  renderSearchValue,
  onValueChange,
  children,
  initialData,
  ...restProps
}: ComboboxControlProps<TData>) {
  // Retrieve initial value from form context
  const { initialValues } = useFormContext<unknown>();
  const nameWithoutId = name.endsWith('.id') ? name.slice(0, -3) : name;
  const autoInitialData = get(initialValues, nameWithoutId) as
    | TData
    | undefined;

  return (
    <FormControl
      {...restProps}
      name={name}
      type='text'
      render={({ ref, userProps, fieldApi, fieldState }) => {
        const displaValue = renderDisplayValue?.(
          initialData ?? autoInitialData,
        );

        return userProps.readOnly && displaValue ? (
          typeof displaValue === 'string' ? (
            <p className='text-primary'>{displaValue}</p>
          ) : (
            displaValue
          )
        ) : (
          <Combobox
            triggerRef={ref}
            initialData={initialData ?? autoInitialData}
            dataProviderFactory={dataProviderFactory}
            renderValue={({ item }) => renderDisplayValue?.(item)}
            variant={fieldState.showError ? 'danger' : 'default'}
            value={fieldState.value as string}
            onValueChange={(value, item, prevValue) => {
              onValueChange?.(value, item, prevValue);
              fieldApi.setTouched(true);
              fieldApi.setValue(value || null);
            }}
            onOpenChange={open => fieldApi.setFocused(open)}
            onCloseAutoFocus={() => fieldApi.setTouched(true)}
            {...userProps}
          >
            {({ item, onSelect, ...restProps }) =>
              children ? (
                children({ item, onSelect, ...restProps })
              ) : (
                <ComboboxItem
                  key={item.id}
                  item={item}
                  value={item.id}
                  onSelect={onSelect}
                  searchValue={renderSearchValue?.(item) ?? item.id}
                  className='w-full'
                >
                  {renderDisplayValue?.(item)}
                </ComboboxItem>
              )
            }
          </Combobox>
        );
      }}
    />
  );
}

// Fix for generics
export const ComboboxControl = memo(
  ComboboxControlBase,
) as typeof ComboboxControlBase;
