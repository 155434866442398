import { cn } from '@utima/ui';
import { cva, type VariantProps } from 'class-variance-authority';
import type { LucideProps } from 'lucide-react';
import {
  memo,
  type ComponentPropsWithoutRef,
  type ReactNode,
  type ElementType,
} from 'react';

// eslint-disable-next-line react-refresh/only-export-components
export const displayValueStyles = cva('', {
  variants: {
    containerSize: {
      sm: 'gap-1',
      md: 'gap-2',
      lg: 'gap-2',
    },
    size: {
      sm: 'text-xs',
      md: 'text-sm',
      lg: 'text-base',
    },
    sizeValue: {
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
    },
  },
});

interface DisplayValueProps
  extends ComponentPropsWithoutRef<'div'>,
    Pick<VariantProps<typeof displayValueStyles>, 'size'> {
  children: ReactNode;
  label?: ReactNode;
  Icon?: ElementType<LucideProps>;
  readOnly?: boolean;
}

export const DisplayValue = memo(function DisplayValue({
  Icon,
  label,
  children,
  size = 'md',
  className,
  readOnly = true,
  ...restProps
}: DisplayValueProps) {
  return (
    <div
      className={cn(
        displayValueStyles({ containerSize: size }),
        'flex flex-col text-foreground',
        className,
      )}
      {...restProps}
    >
      <div
        className={cn(displayValueStyles({ size }), readOnly && 'font-bold')}
      >
        {label}
      </div>
      <div className={cn(displayValueStyles({ sizeValue: size }))}>
        {Icon ? (
          <div className='flex items-center gap-2'>
            <Icon className='size-5' />
            <span>{children}</span>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
});
