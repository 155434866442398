import {
  keepPreviousData,
  useQuery,
  type QueryKey,
} from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type TableOptions,
} from '@tanstack/react-table';
import set from 'lodash.set';
import { memo, useCallback } from 'react';

import type { TableConsumerProps, DataTableRowHandler } from './dataTableTypes';
import { DesktopTable } from './DesktopTable';
import { Pagination } from './Pagination';
import { TableHeader } from './TableHeader';

// Used instead of inline [] to prevent unnecessary re-renders
const DEFAULT_DATA: never[] = [];

// Re-export for convenience
export { type DataTableRowHandler };
export type DataTableQueryResponse<T> = {
  items: T[];
  pageCount: number;
};

export type DataTableQuery<T> = (query: {
  page: number;
  pageSize: number;
  search: string;
  sort: Record<string, any>;
}) => Promise<DataTableQueryResponse<T> | undefined>;

export type DataTableProps<TData> = {
  columns: ColumnDef<TData, any>[];
  options?: Omit<TableOptions<TData>, 'columns' | 'data' | 'getCoreRowModel'>;
  queryKey: QueryKey;
  showSearch?: boolean;
  onQueryFn: DataTableQuery<TData>;
} & Pick<TableConsumerProps<TData>, 'onRow'>;

/**
 * Converts sort string format to object format
 * that is more suitable for querying.
 */
export function convertSortToObjectFormat(
  sort: string | undefined,
): Record<string, any> {
  if (!sort) {
    return {};
  }

  // Handle this recursively when key is dost
  const [key, direction] = sort.split(':');

  return set({}, key, direction);
}

/**
 * Basic data table component, used to fetch and display paginated
 * data in tables for any defined resource. Component expects column
 * definition, query key and query handler. This is to make it as abstract
 * as possible.
 */
function DataTableBase<TData>({
  columns,
  options,
  queryKey,
  onQueryFn,
  showSearch,
  onRow,
}: DataTableProps<TData>) {
  const navigate = useNavigate();
  const {
    page = 1,
    pageSize = 10,
    sort,
    search,
  } = useSearch({ strict: false }) as any;

  const handleSearchChange = useCallback(
    (searchValue: string | undefined) => {
      if (typeof searchValue !== 'string') {
        return;
      }

      navigate({
        // @ts-expect-error this is PITA to type
        search: prev => ({
          ...prev,
          // Reset search to nothing when searchValue is empty
          search: searchValue === '' ? undefined : searchValue,
          // Always reset to page 1 when searching
          page: 1,
        }),
      });
    },
    [navigate],
  );

  /**
   * Handle data fetching using on onQuery request handler
   * and react-query.
   */
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [...queryKey, { pageSize, page, search, sort }],
    queryFn: () =>
      onQueryFn({
        page,
        pageSize,
        search,
        sort: convertSortToObjectFormat(sort),
      }),
    placeholderData: keepPreviousData,
  });

  /**
   * Init react-table instance, which is used to render
   * table and handle all table related logic.
   */
  const table = useReactTable<TData>({
    columns,
    data: data?.items ?? DEFAULT_DATA,
    pageCount: data?.pageCount ?? 0,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      sort,
      pagination: {
        // Index starts from 0
        pageIndex: page - 1,
        pageSize,
      },
    },
    ...options,
  });

  return (
    <>
      <TableHeader
        showSearch={showSearch}
        search={search}
        onSearchChange={handleSearchChange}
      />
      <DesktopTable
        loading={isLoading || isFetching}
        table={table}
        onRow={onRow}
      />
      <Pagination table={table} />
    </>
  );
}

// Fix for generics
export const DataTable = memo(DataTableBase) as typeof DataTableBase;
