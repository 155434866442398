import { Relevant, Scope } from 'informed';

import { useFormSchemaContext } from './FormSchemaContext';
import type { FormSchemaDef } from './formUtils';

export type SchemaFieldsProps = {
  schema: FormSchemaDef;
};

// TODO pass ui props
// TODO filter out props, that should nto be passed to the component

export function SchemaFields({ schema }: SchemaFieldsProps) {
  const { adapter } = useFormSchemaContext();

  if (!schema?.$fields) {
    return null;
  }

  return schema.$fields.map((field, index) => {
    if ('$scope' in field && field.$scope) {
      return (
        <Scope
          key={`scope-${index.toString()}`}
          scope={field.$scope.$scopeName}
        >
          <SchemaFields schema={field.$scope} />
        </Scope>
      );
    }

    if ('$relevant' in field && field.$relevant) {
      return (
        <Relevant
          key={`relevant-${index.toString()}`}
          when={field.$relevant.$when}
        >
          <SchemaFields schema={field.$relevant} />
        </Relevant>
      );
    }

    if ('visible' in field && field.visible === false) {
      return null;
    }

    if ('name' in field && field.name) {
      // Destructure props with defaults
      const { type = 'text', uiProps, uiControl, ...fieldProps } = field;

      const key = `field-${field.name}-${index.toString()}`;
      const FieldComponent = adapter[uiControl ?? type] as any;

      return (
        <FieldComponent key={key} type={type} {...fieldProps} {...uiProps} />
      );
    }

    console.error('Invalid schema field:', field);

    return null;
  });
}
